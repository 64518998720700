import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

import GroupAddIcon from '@mui/icons-material/GroupAdd';
import CloseIcon from '@mui/icons-material/Close';

import { postProyectos } from '../services/apis';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export const CrearProyecto = ({ open, setOpen }) => {
    const Navigate = useNavigate();

    const [nombrePy, setNombrePy] = useState("");

    const handleClose = () => {
        setOpen(false);
    };


    const crearProyecto = async (nombrePy) => {
        try {
            const response = await postProyectos(nombrePy);
            //console.log("Respuesta", response)
            if (response.success) {
                Navigate("/trabajos/" + response.lastId)
            }


        } catch (error) {
            //console.log("Error al añadir a la Cesta")
        }
    }

    const handledOnChange = (event) => {
        setNombrePy(event.target.value);
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                // fullScreen
                fullWidth
                TransitionComponent={Transition}
            >
                <DialogContent>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        xs={12}
                        gap={2}
                    >
                        <Grid container xs={12} sx={{ mb: -5, mt: -2 }} justifyContent="end" alignItems="center" className='pointer no-select' onClick={() => handleClose()}>
                            <CloseIcon sx={{ fontSize: 30 }} color='disabled' />
                        </Grid>
                        <Grid container xs={2} md={1} style={{ marginRight: 30 }}>
                            <GroupAddIcon sx={{ fontSize: 80 }} color='azul' />
                        </Grid>
                        <Grid container xs={8} md={6}
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                        >
                            <Typography variant="h4" >
                                Crear Proyecto
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom className='texto-gris'>
                                Crear proyecto de trabajo
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="flex-start"
                        xs={12}
                    >
                        <Typography variant="h6" gutterBottom>
                            Nombre del proyecto
                        </Typography>
                        <TextField value={nombrePy} onChange={handledOnChange} id="outlined-basic" label="Nombre del proyecto" variant="outlined" fullWidth />
                    </Grid>

                    <Button color='azul' variant="contained" fullWidth sx={{ mt: 2, p: 1.5 }} onClick={() => crearProyecto(nombrePy)}>Crear proyecto</Button>

                </DialogContent>
            </Dialog>
        </>
    )
}
