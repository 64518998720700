import React from 'react'

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export const SelectEstado = ({ infoTrabajo, setInfoTrabajo }) => {

    const handleChange = (event) => {
        setInfoTrabajo(prevState => ({
            ...prevState,       // Mantener los demás campos del objeto
            estado: event.target.value // Actualizar solo el campo estado
        }));
    };

    return (
        <>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Estado</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={infoTrabajo.estado}
                    label="Estado"
                    onChange={handleChange}
                >
                    <MenuItem value={"Iniciado"}>Iniciado</MenuItem>
                    <MenuItem value={"Pausado"}>Pausado</MenuItem>
                </Select>
            </FormControl>
        </>
    )
}
