import { Fab, Grid, Tooltip } from '@mui/material'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';


import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { getCookie } from '../../utils/cookies';
import LogoutIcon from '@mui/icons-material/Logout';


export const CerrarSesion = () => {

    const Navigate = useNavigate();
    const token = getCookie('PHPSESSID');

    const [logOut, setLogOut] = useState(false);


    const handleClose = () => {
        setLogOut(false);
    };

    const cerrarSesion = async () => {


        localStorage.removeItem('token');
        localStorage.removeItem('username');
        localStorage.removeItem('code');
        localStorage.removeItem('mail');
        localStorage.removeItem('DesRepresentante');
        document.cookie = "PHPSESSID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";


        Navigate("/login");

        try {
            const response = await axios.post('https://compras.clasol.com/WS/Logout.php', {
                'token': token,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            //console.log('Respuesta del servidor:', response.data);


        } catch (error) {
            console.error('Error al invalidar el token', error);
        }
    };

    return (
        <>
            <Tooltip title="Cerrar sesión">
                <Fab color='azul' aria-label="Cerrar sesión" style={{ margin: '1em 00.5em', position: 'fixed', bottom: 1, left: 1 }}>
                    <LogoutIcon onClick={() => setLogOut(true)} />
                </Fab>
            </Tooltip>


            {/* --------------------------------------------------------------DIALOG--------------------------------------------------------------- */}

            <Dialog
                open={logOut}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">

                    <Typography variant="h4" style={{ textAlign: 'left' }}>
                        <LogoutIcon color='azul' sx={{ fontSize: 40, mb: -0.8 }} /> Cerrar sesion
                    </Typography>


                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">

                        <Typography variant="body1" style={{ textAlign: 'left' }}>
                            Desea cerrar la sesion?
                        </Typography>

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        fullWidth
                        sx={{ m: 1 }}
                        size='large'
                        variant="outlined"
                        onClick={() => setLogOut(false)}
                        autoFocus
                    >
                        Cancelar
                    </Button>
                    <Button
                        fullWidth
                        sx={{ m: 1 }}
                        size='large'
                        variant="contained"
                        onClick={() => cerrarSesion()}
                        autoFocus
                    >
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog >
        </>
    )
}
