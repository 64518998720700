import { TextField, Typography } from '@mui/material'
import React from 'react'

export const PercioVentaPrecioCompra = ({ articulo, handledOnChange }) => {
    return (
        <>
            {articulo && (
                <>

                    <Typography variant="h5" >
                        Precio Compra (€)
                    </Typography>


                    <TextField
                        fullWidth
                        id="outlined-basic"
                        // label="Precio €"
                        variant="outlined"
                        color='azul'
                        type='number'
                        name='precioCompra'
                        value={articulo[0].precioCompra}
                        onChange={handledOnChange}
                        inputProps={{ step: 0.01 }}
                        sx={{ marginTop: '0.5em' }}
                    />

                    <Typography variant="h5" >
                        Precio Venta (€)
                    </Typography>


                    <TextField
                        fullWidth
                        id="outlined-basic"
                        // label="Precio €"
                        variant="outlined"
                        color='azul'
                        type='number'
                        name='precio'
                        value={articulo[0].precio}
                        onChange={handledOnChange}
                        inputProps={{ step: 0.01 }}
                        sx={{ marginTop: '0.5em' }}
                    />
                </>
            )}
        </>

    )
}
