import React, { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';

import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import Pagination from '@mui/material/Pagination';


import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import DescriptionIcon from '@mui/icons-material/Description';

import { DialogFinalizar } from './DialogFinalizar';
import { updateEstado } from '../services/apis';

export const ListaTrabajos = ({ trabajos, setTrabajos, setTrabajosFinal, idProyecto }) => {
    const Navigate = useNavigate();

    const [open, setOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1); // Estado para la página actual
    // sdasd

    const itemsPerPage = 54; // Máximo de artículos por página

    // Manejador para cambiar de página
    const handlePageChange = (event, value) => {
        setCurrentPage(value);
        handleScrollToTop();
    };

    const handleScrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth", // Desplazamiento suave
        });
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const cambiarFormatoFecha = (fechaCompleta) => {
        // Dividimos la parte de la fecha y la hora
        const [fechaISO, hora] = fechaCompleta.split(' ');

        // Dividimos la fecha ISO en [año, mes, día]
        const [year, month, day] = fechaISO.split('-');

        // Creamos el nuevo formato de fecha "día/mes/año"
        const fechaFormateada = `${day}/${month}/${year}`;

        // Retornamos el nuevo formato completo: "fecha ISO fecha formateada - hora"
        return `${fechaFormateada} - ${hora}`;
    };

    const getCardStyle = (estado) => {
        switch (estado) {
            case "2":
                return { backgroundColor: '#c9f7cb' };
            case "0":
                return { backgroundColor: '#FFFFFF' };
            case "1":
                return { backgroundColor: '#f7cbcb' };
            case "3":
                return { backgroundColor: '#e1cbf7' };
            default:
                return { backgroundColor: 'transparent' };
        }
    };

    const getIcon = (estado) => {
        switch (estado) {
            case "2":
                return (<Grid item xs={2} sx={{ mt: -1 }} className='no-select'>
                    <Tooltip
                        title="Finalizado"
                        placement="top" color='error'
                        arrow
                        TransitionComponent={Zoom}
                    >
                        <CheckCircleIcon color='verde' fontSize='medium' />
                    </Tooltip>
                </Grid>);
            case "0":
                return (<Grid item xs={2} sx={{ mt: -1 }} className='no-select'>
                    <Tooltip
                        title="Iniciado"
                        placement="top" color='error'
                        arrow
                        TransitionComponent={Zoom}
                    >
                        <PlayCircleIcon color='negro' fontSize='medium' />
                    </Tooltip>
                </Grid>);
            case "1":
                return (<Grid item xs={2} sx={{ mt: -1 }} className='no-select'>
                    <Tooltip
                        title="Pausado"
                        placement="top" color='error'
                        arrow
                        TransitionComponent={Zoom}
                    >
                        <PauseCircleIcon color='rojo' fontSize='medium' />
                    </Tooltip>
                </Grid>);
            case "3":
                return (<Grid item xs={2} sx={{ mt: -1 }} className='no-select'>
                    <Tooltip
                        title="Exportado"
                        placement="top" color='error'
                        arrow
                        TransitionComponent={Zoom}
                    >
                        <DescriptionIcon color='morado' fontSize='medium' />
                    </Tooltip>
                </Grid>);
            default:
                return null;
        }
    };

    const cambiarEstado = async (estado, idTrabajo, idProyecto) => {
        try {
            const response = await updateEstado(estado, idTrabajo, idProyecto);
            //console.log("AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA", response);

            if (response.success) {
                setTrabajos(response.trabajos);

              
                setOpen(null)
            }

        } catch (error) {
            console.error("Error:", error);
        }
    }


    // Cálculo para dividir el array en páginas
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentTrabajos = trabajos.slice(indexOfFirstItem, indexOfLastItem);

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            xs={12}
            gap={2}
            className='load'
        >
            {currentTrabajos.map((info, index) => (
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    xs={12} md={2.5}
                    className='borde-redondo sombra-corta card-articulo load'
                    padding={2}
                    key={info.idTrabajo}
                    style={getCardStyle(info.id_estado)}
                >
                    <Grid item xs={10}>
                        <Typography variant="h5" style={{ textAlign: 'left', marginBottom: '0.5em', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            {info.nombre}
                        </Typography>
                    </Grid>

                    {getIcon(info.id_estado)}

                    <div className="separador-azul" style={{ marginBottom: '0.5em' }}></div>

                    <Grid item xs={12} style={{ textAlign: 'left', marginBottom: '0.5em' }}>
                        <Typography variant="caption" style={{ textAlign: 'left', marginBottom: '1em', fontWeight: '400' }}>
                            Fecha: {cambiarFormatoFecha(info.fecha)}
                        </Typography>
                    </Grid>
                    <Grid container xs={12} justifyContent="space-between" alignItems="center">
                        <Typography variant="h6" style={{ textAlign: 'left' }}>
                            Trabajo Nº{info.idTrabajo}
                        </Typography>
                    </Grid>

                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        xs={12}
                        sx={{ mt: 1 }}
                    >
                        {(() => {
                            switch (info.id_estado) {
                                case "0":
                                    return (
                                        <ButtonGroup fullWidth variant="outlined" aria-label="Basic button group">
                                            <Button color='azul' onClick={() => Navigate("/editar-trabajo/" + info.idTrabajo)} size='small'>Editar</Button>
                                            <Button color='azul' onClick={() => cambiarEstado(1, info.idTrabajo, idProyecto)} size='small'>Pausar</Button>
                                        </ButtonGroup>
                                    );
                                case "1":
                                    return (
                                        <ButtonGroup fullWidth variant="outlined" aria-label="Basic button group">
                                            <Button color='azul' onClick={() => Navigate("/editar-trabajo/" + info.idTrabajo)} size='small'>Editar</Button>
                                            <Button color='azul' onClick={() => cambiarEstado(0, info.idTrabajo, idProyecto)} size='small'>Iniciar</Button>
                                        </ButtonGroup>
                                    );
                                case "2":
                                    return (
                                        <ButtonGroup fullWidth variant="outlined" aria-label="Basic button group">
                                            <Button variant="outlined" color='azul' onClick={() => Navigate("/trabajo-finalizado/" + info.idTrabajo)} size='small'>Ver</Button>
                                            <Button color="azul" size="small" onClick={() => setOpen(index)}>Exportar</Button>
                                        </ButtonGroup>
                                    );
                                case "3":
                                    return (
                                        <ButtonGroup fullWidth variant="outlined" aria-label="Basic button group">
                                            <Button variant="outlined" color='azul' onClick={() => Navigate("/trabajo-finalizado/" + info.idTrabajo)} size='small'>Ver</Button>
                                        </ButtonGroup>
                                    );
                                default:
                                    return null; // No mostrar nada si no coincide con ningún caso
                            }
                        })()}

                        <DialogFinalizar index={index} open={open} setOpen={setOpen} tipo={"trabajo"} cambiarEstado={cambiarEstado} idTrabajo={info.idTrabajo} setTrabajos={setTrabajos} idProyecto={idProyecto}></DialogFinalizar>
                    </Grid>
                </Grid>

            ))}

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                xs={12}
                sx={{ mt: 5 }}
            >
                <Pagination
                    count={Math.ceil(trabajos.length / itemsPerPage)}
                    page={currentPage}
                    siblingCount={0}
                    onChange={handlePageChange}
                    color="azul"
                    shape="rounded"
                />
            </Grid>
        </Grid>
    )
}
