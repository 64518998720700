import { Button, Card, CardContent, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Collapse } from '@mui/material';
import React, { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import PlaceIcon from '@mui/icons-material/Place';

export const FichajeCard = ({ fichaje, nombreMes }) => {
    let dias = fichaje.dias;

    const [desplegado, setDesplegado] = useState(false);

    // Función para formatear los segundos a hh:mm:ss
    function formatTime(segundos) {
        const horas = Math.floor(segundos / 3600);
        const minutos = Math.floor((segundos % 3600) / 60);
        const seg = segundos % 60;
        // return `${String(horas).padStart(2, '0')}:${String(minutos).padStart(2, '0')}:${String(seg).padStart(2, '0')}`;
        return `${String(horas).padStart(2, '0')}:${String(minutos).padStart(2, '0') + " H"}`;

    }

    function sumarTiempos(registros) {
        // Filtra solo los registros que tienen tanto hora_inicio como hora_fin
        let registrosCerrados = registros.filter(reg => reg.hora_fin && reg.hora_inicio);
        let total = registrosCerrados.reduce((acumulador, reg) => acumulador + parseInt(reg.total), 0);
        return formatTime(total);
    }
    // Función para abrir Google Maps con las coordenadas
    const handleClick = (latitud, longitud) => {
        const url = `https://www.google.com/maps?q=${latitud},${longitud}`;
        window.open(url, '_blank');
    };

    return (
        <Grid
            container
            xs={12}
            md={8}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            className='borde-redondo sombra-corta pointer card-articulo'
            marginBottom={"0.5em"}
            padding={3}
        >
            <Grid
                container
                xs={12}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Grid container xs={5} md={2} justifyContent="start" alignItems="center">
                    <strong>{nombreMes + " " + fichaje.fecha.split("-")[2]}</strong>
                </Grid>
                <Grid container xs={12} md={9} justifyContent="start" alignItems="center" onClick={() => setDesplegado(!desplegado)}>
                    <Grid container xs={5} md={2}>
                        <strong>{sumarTiempos(fichaje.registros)} </strong> &nbsp;
                    </Grid>
                    <Grid container xs={5} md={10}>
                        <strong> Turnos: {fichaje.registros.length}</strong>
                    </Grid>
                </Grid>
                <Grid container xs={12} md={1} justifyContent="end" alignItems="center">
                    <Button onClick={() => setDesplegado(!desplegado)}>
                        {desplegado ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </Button>
                </Grid>
            </Grid>
            <Collapse in={desplegado} style={{ minWidth: '100%' }}>
                <Grid container xs={12} justifyContent="center" alignItems="center" marginTop="1em">
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell><strong>Horas Trabajadas</strong></TableCell>
                                    <TableCell><strong>Inicio Turno</strong></TableCell>
                                    <TableCell><strong>Final Turno</strong></TableCell>
                                    <TableCell align="center"><strong>Ubicación Inicio</strong></TableCell>
                                    <TableCell align="center"><strong>Ubicación Fin</strong></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {fichaje.registros.map((reg, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{formatTime(reg.total)}</TableCell>
                                        <TableCell>{reg.hora_inicio.split(" ")[1]}</TableCell>
                                        <TableCell>{reg.hora_fin ? reg.hora_fin.split(" ")[1] : 'En Curso'}</TableCell>

                                        <TableCell align="center">
                                            <Button
                                                variant="contained"
                                                size="small"
                                                onClick={() => handleClick(reg.latitud_inicio, reg.longitud_inicio)}
                                            >
                                                <PlaceIcon fontSize="small" /> Inicio
                                            </Button>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Button
                                                variant="contained"
                                                size="small"
                                                disabled={(reg.latitud_fin || reg.longitud_fin) == null}
                                                onClick={() => handleClick(reg.latitud_fin, reg.longitud_fin)}
                                            >
                                                <PlaceIcon fontSize="small" /> Fin
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Collapse>
        </Grid>
    );
};