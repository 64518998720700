import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

export const SelectorYear = ({ years, setYearSelected, yearSelected }) => {
    return (
        <FormControl fullWidth>
            <InputLabel id="selector-year-label">Seleccionar Año</InputLabel>
            <Select
                labelId="selector-year-label"
                value={yearSelected} // Se asegura de que el valor coincida
                onChange={(event) => setYearSelected(event.target.value)}
                label="Seleccionar Año"
            >
                {years.map((year) => (
                    <MenuItem key={year} value={year}>
                        {year}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

