import React, { useState, useCallback, useRef } from 'react'
import Webcam from "react-webcam";
import { BrowserMultiFormatReader } from "@zxing/browser";

import Button from '@mui/material/Button';

import Collapse from '@mui/material/Collapse';


import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';


export const Camara = ({ camara, setCamara, barcode, setBarcode, buscarArticulo, articulo, setArticulo }) => {

    const [isScanning, setIsScanning] = useState(false);
    const webcamRef = useRef(null);

    // Configuración de la cámara
    const videoConstraints = {
        facingMode: "environment", // Usar la cámara trasera
    };

    // Método para capturar la imagen de la cámara
    const capture = useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        if (imageSrc) {
            scanBarcode(imageSrc); // Intentar escanear el código de barras
        }
    }, [webcamRef]);

    // Método para escanear el código de barras
    const scanBarcode = async (imageSrc) => {
        setIsScanning(true);
        const reader = new BrowserMultiFormatReader();
        try {
            const result = await reader.decodeFromImageUrl(imageSrc);
            const cleanBarcode = result.getText().trim(); // Elimina los espacios al inicio y al final
            setBarcode(cleanBarcode); // Guarda el código limpio

            setArticulo(prevArticulo => {
                const nuevoArticulo = [...prevArticulo];
                nuevoArticulo[0].codBarras = cleanBarcode;
                nuevoArticulo[1].codBarras = cleanBarcode;
                return nuevoArticulo;
            });

            buscarArticulo("codBar", cleanBarcode); // Busca el artículo con el código limpio
        } catch (err) {
            console.error("Error escaneando el código de barras:", err);
            setBarcode(null);
        }
        setIsScanning(false);
    };


    return (
        <>
            <Collapse in={camara} >
                <Webcam
                    audio={false}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    videoConstraints={videoConstraints}
                    style={{ width: "100%", height: "auto" }}
                    className='borde-redondo'
                />
                <Button color='azul' variant="contained" size="large" fullWidth onClick={capture} style={{ padding: '0.7em' }}> <QrCodeScannerIcon fontSize='large' />&nbsp;Escanear codigo </Button>

            </Collapse >
        </>
    )
}
