import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import GroupIcon from '@mui/icons-material/Group';
import GroupAddIcon from '@mui/icons-material/GroupAdd';


import { Header } from '../components/Header';
import { getEstadosProyectos, getProyectos, getProyectosFecha } from '../services/apis';
import { ListaProyectos } from '../components/ListaProyectos';
import { SubHeader } from '../components/SubHeader';
import { CerrarSesion } from '../components/Botones/CerrarSesion';
import { FiltrosTrabajos } from '../components/FiltrosTrabajos';
import { CrearProyecto } from '../components/CrearProyecto';


export const Proyectos = () => {


    const [proyectos, setProyectos] = useState([]);
    const [proyectosFinal, setProyectosFinal] = useState([]);
    const [mostrarFinal, setMostrarFinal] = useState(false)
    const [proyectosFiltrados, setProyectosFIltrados] = useState([]);
    const [estados, setEstados] = useState([]);
    const [fechaInicio, setFechaInicio] = useState("2024-01-01");
    const [fechaFin, setFechaFin] = useState("2100-01-01");
    const [filtrar, setFiltrar] = useState(false)
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };


    const handledOnChange = (event) => {
        //console.log(event.target);
        let value = event.target.value;
        let name = event.target.name;

        switch (name) {
            case "fechaInicio":
                setFechaInicio(value);
                setFiltrar(true)
                break;

            case "fechaFin":
                setFechaFin(value);
                setFiltrar(true)
                break;

            default:

                break;
        }
    }


    const filtrarProyectos = (array) => {

        array = array.filter(item => item.finalizado !== 1);

        return array;
    }

    const cargarProyectos = async (fechaInicio, fechaFin) => {
        try {
            const response = await getProyectosFecha(fechaInicio, fechaFin);
            //console.log("aa", response.data)
            setProyectosFinal(response.data)
            setProyectos(filtrarProyectos(response.data))
            setFiltrar(false)
        } catch (error) {
            console.error("Error al añadir el cliente:", error);
        }
    }

    const cargarEstados = async () => {
        try {
            const response = await getEstadosProyectos();
            setEstados(response.data);

        } catch (error) {
            console.error("Error al añadir el cliente:", error);
        }

    }

    useEffect(() => {
        cargarProyectos(fechaInicio, fechaFin);
        cargarEstados();
    }, []);

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            xs={12}
            className='load'
            sx={{ pb: 10 }}
        >

            <Header></Header>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                xs={11} md={10}
            >
                <SubHeader></SubHeader>
            </Grid>



            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                xs={11} md={10}
                // className='borde-redondo sombra-corta'
                padding={2}
                gap={3}
            >
                <Grid
                    container
                    xs={12}
                    justifyContent="space-between"
                    alignItems="center"
                    gap={2}
                >
                    <Grid
                        container
                        direction="row"
                        xs={12} md={3}
                        justifyContent="flex-start"
                        alignItems="center"
                    >
                        <Grid item xs={2} md={2}>
                            <GroupIcon sx={{ fontSize: 50 }} color='negro' />
                        </Grid>

                        <Grid item xs={9} md={9} alignItems="flex-start">
                            <Typography variant="h4" style={{ textAlign: 'left' }}>
                                Lista de proyectos
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        direction="row"
                        xs={12} md={8}
                        justifyContent="flex-end"
                        alignItems="center"
                        gap={1}
                    >

                        <Grid item xs={12} md={2} >
                            <TextField
                                fullWidth
                                id="fecha-inicio"
                                label="Fecha de inicio"
                                variant="outlined"
                                type="date"
                                value={fechaInicio}
                                color="azul"
                                name="fechaInicio"
                                InputLabelProps={{
                                    shrink: true, // Asegura que el label no se superponga
                                }}
                                onChange={handledOnChange} // Maneja los cambios
                            />
                        </Grid>
                        <Grid item xs={12} md={2} >
                            <TextField
                                fullWidth
                                id="outlined-basic"
                                label="Fecha hasta..."
                                variant="outlined"
                                type='date'
                                value={fechaFin}
                                color='azul'
                                name='fechaFin'
                                InputLabelProps={{
                                    shrink: true, // Asegura que el label no se superponga
                                }}
                                onChange={handledOnChange} // Maneja los cambios
                            />
                        </Grid>

                        {
                            filtrar ?
                                <Grid item xs={12} md={1}>
                                    <Button color='azul' fullWidth variant='outlined' sx={{ pt: 1.8, pb: 1.8 }} onClick={() => cargarProyectos(fechaInicio, fechaFin)}>Filtrar</Button>
                                </Grid>
                                : ''
                        }
                        <Grid item xs={12} md={2.5}>
                            <Button fullWidth color='azul' variant='contained' onClick={handleClickOpen} sx={{ pt: 0.8, pb: 0.8 }}> <GroupAddIcon sx={{ fontSize: 25, m: 1 }} color='blanco' /> Crear proyecto</Button>
                        </Grid>

                    </Grid>
                    <div className="separador"></div>
                </Grid>
            </Grid>
            {proyectos.length >= 1 && (

                <FiltrosTrabajos trabajos={proyectos} setTrabajosFiltrados={setProyectosFIltrados} estados={estados} setEstados={setEstados} ></FiltrosTrabajos>

            )}
            {proyectosFiltrados.length <= 0
                ? <div>
                    <Typography variant="h4">
                        No hay trabajos
                    </Typography>
                </div> :

                <ListaProyectos proyectos={proyectosFiltrados} setProyectos={setProyectos}></ListaProyectos>
            }



            <CrearProyecto open={open} setOpen={setOpen}></CrearProyecto>



            <CerrarSesion></CerrarSesion>
        </Grid>
    )
}
