import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import Button from '@mui/material/Button';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import StopCircleIcon from '@mui/icons-material/StopCircle';


import CircleIcon from '@mui/icons-material/Circle';
import InfoIcon from '@mui/icons-material/Info';
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import { finFichaje, getFichaje, inicioFichaje } from '../../services/apis';
import { CircularProgress } from '@mui/material';

export const Fichaje = () => {
    const Navigate = useNavigate();
    let tamIco = 70;
    const [inciado, setIniciado] = useState(false);
    const [segundos, setSegundos] = useState("");
    const [localizacion, setLocalizacion] = useState({});
    const [tiempoTranscurrido, setTiempoTranscurrido] = useState(0);
    const [turnos, setTurnos] = useState([]); // Para almacenar los turnos del día
    const [ultimaEntrada, setUltimaEntrada] = useState(null); // Para mostrar la última entrada o salida
    const [loaded, setLoaded] = useState(false);

    const obtenerFechaActual = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };

    const obtenersoloFechaActual = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const finalizarFichaje = async () => {
        try {
            const fechaActual = obtenerFechaActual();
            const response = await finFichaje({ "fecha": fechaActual, "localizacion": localizacion });
            setIniciado(false);
            consultarFichaje(); // Actualizar la vista después de finalizar
        } catch (error) {
            console.error("Error al finalizar fichaje:", error);
            localStorage.clear();
            Navigate("/login");
        }
    };

    const sumarSegundos = (fichajes) => {
        if (Array.isArray(fichajes)) {
            const sumaTotal = fichajes.reduce((acumulador, fichaje) => {
                if (fichaje.hora_fin) {
                    return acumulador + parseInt(fichaje.total, 10);
                } else {
                    const horaActual = new Date();
                    const horaInicio = new Date(fichaje.hora_inicio);
                    const diferenciaSegundos = Math.floor((horaActual - horaInicio) / 1000);
                    acumulador += diferenciaSegundos;
                    setIniciado(true);
                }
                return acumulador;
            }, 0);
            setSegundos(sumaTotal);

            // Establecer el último fichaje
            if (fichajes.length > 0) {
                setUltimaEntrada(fichajes[fichajes.length - 1]);
            }
        }
    };

    const iniciarfichaje = async () => {
        const fechaActual = obtenerFechaActual();
        try {
            const response = await inicioFichaje({ "fecha": fechaActual, "localizacion": localizacion });
            setIniciado(true);
            consultarFichaje(); // Actualizar la vista después de iniciar
        } catch (error) {
            console.error("Error al iniciar el fichaje:", error);
            localStorage.clear();
            Navigate("/login");
        }
    };

    const consultarFichaje = async () => {
        const fechaActual = obtenersoloFechaActual();
        try {
            const response = await getFichaje(fechaActual);
            setTurnos(response.data); // Guardar los turnos completos del día
            sumarSegundos(response.data);
            setLoaded(true);
        } catch (error) {
            console.error("Error al consultar fichajes:", error);
            localStorage.clear();
            Navigate("/login");
        }
    };

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setLocalizacion({ latitude, longitude });
                },
                (error) => {
                    console.error(`Error Code = ${error.code} - ${error.message}`);
                }
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
        }
        consultarFichaje();
    }, []);

    const formatTime = (segundos) => {
        const horas = Math.floor(segundos / 3600);
        const minutos = Math.floor((segundos % 3600) / 60);
        const seg = segundos % 60;
        return `${String(horas).padStart(2, '0')}:${String(minutos).padStart(2, '0')}:${String(seg).padStart(2, '0')}`;
    };

    const formatHour = (dateTime) => {
        const time = new Date(dateTime);
        return `${String(time.getHours()).padStart(2, '0')}:${String(time.getMinutes()).padStart(2, '0')}`;
    };

    const renderTooltipContent = () => (
        <div>
            <Typography variant="body2"><strong>Turnos del día:</strong></Typography>
            {turnos.map((turno, index) => (
                <Typography key={index} variant="body2">
                    {formatHour(turno.hora_inicio)} - {turno.hora_fin ? formatHour(turno.hora_fin) : 'En curso'}
                </Typography>
            ))}
            <Typography variant="body2" sx={{ mt: 1 }}>
                <strong>Jornada Total:</strong> {formatTime(segundos)}
            </Typography>
        </div>
    );

    useEffect(() => {
        let intervalo;
        if (inciado) {
            intervalo = setInterval(() => {
                setTiempoTranscurrido((prev) => prev + 1);
            }, 1000);
        }
        return () => clearInterval(intervalo);
    }, [inciado]);


    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            xs={11} md={5}
            className='borde-redondo sombra-corta pointer card-articulo'
            // onClick={() => Navigate("/ver-stock")}
            padding={2}
            gap={3}
        >
            <Grid container xs={12} md={2} style={{ marginRight: 10 }}>
                <AccessAlarmsIcon sx={{ fontSize: tamIco }} color='disabled' />
            </Grid>

            <Grid
                container
                direction="column"
                justifyContent="start"
                alignItems="start"
                xs={12} sm={9} md={8} xl={9}
            >
                {!loaded && (
                    <CircularProgress size={40} />
                )}
                {loaded && (
                    <>
                        <Grid
                            container
                            xs={12}
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-start"
                            sx={{ mt: -1 }}
                        >
                            <Typography variant="h6" >
                                Fichaje
                            </Typography>
                            {/* <Tooltip
                                title="Informacion sobre el fichaje"
                                slots={{ transition: Zoom }}
                                placement="right-start"
                            >
                                <InfoIcon color='disabled' />
                            </Tooltip> */}


                        </Grid>

                        {ultimaEntrada && (
                            <>
                                <Grid
                                    container
                                    xs={12}
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    sx={{ mt: -1 }}
                                >
                                    {
                                        ultimaEntrada.hora_fin != null && (
                                            <>
                                                <Typography variant="caption">
                                                    <CircleIcon sx={{ fontSize: 10 }} color='error' /> No Activo
                                                </Typography>
                                                {/* <Button variant="outlined" size='small' color='azul'>Entrada</Button> */}
                                            </>
                                        )
                                    }
                                    {
                                        ultimaEntrada.hora_fin === null && (
                                            <>
                                                <Typography variant="caption">
                                                    <CircleIcon sx={{ fontSize: 10 }} color='success' /> Activo
                                                </Typography>
                                                {/* <Button variant="outlined" size='small' color='azul'>Entrada</Button> */}
                                            </>
                                        )
                                    }
                                    {ultimaEntrada && (
                                        <Typography variant="subtitle2" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                            Última {ultimaEntrada.hora_fin ? "Salida" : "Entrada"}: {ultimaEntrada.hora_fin ? formatHour(ultimaEntrada.hora_fin) : formatHour(ultimaEntrada.hora_inicio)}
                                            {/* Mostrar indicador "Activo" si está en curso */}
                                        </Typography>
                                    )}
                                </Grid>
                            </>
                        )
                        }


                        {!inciado ? (
                            <Button
                                variant="outlined"
                                color='azul'
                                fullWidth
                                endIcon={<PlayCircleOutlineIcon color='azul' sx={{ mt: -0.4 }} />}
                                onClick={iniciarfichaje}
                            >
                                Fichar
                            </Button>
                        ) : (
                            <Button
                                variant="outlined"
                                color='azul'
                                fullWidth
                                endIcon={<StopCircleIcon color='azul' sx={{ mt: -0.35 }} />}
                                onClick={finalizarFichaje}
                            >
                                Salida
                            </Button>
                        )}
                    </>
                )}
            </Grid>

        </Grid>
    )
}
