import React, { useEffect, useState } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { Inicio } from '../pages/Inicio'
import { Login } from '../pages/Login';
import { VerStock } from '../pages/VerStock';
import { EditarStock } from '../pages/EditarStock';
import { AnadirStock } from '../pages/AnadirStock';
import { CrearTrabajo } from '../pages/CrearTrabajo';
import { Trabajos } from '../pages/Trabajos';
import { EditarTrabajo } from '../pages/EditarTrabajo';

import PrivateRoute from './PrivateRoute';
import { TrabajoFinalizado } from '../pages/TrabajoFinalizado';
import { Proyectos } from '../pages/Proyectos';
import { Fichajes } from '../components/fichaje/Fichajes';
import { FichajesUsuario } from '../components/fichaje/FichajesUsuario';
import { RecursosHumanos } from '../components/rrhh/RecursosHumanos';
import { Usuarios } from '../components/administrador/usuarios/Usuarios';
import { RolesYDepartamentos } from '../components/administrador/permisos/RolesYDepartamentos';
import { Admin } from '../components/administrador/Admin';
import { Footer } from '../components/Footer';


export const Router = () => {
    //-----------------------------------------------------------------------------------------------v1.0.2--------------------------------------------------------------------------------------------

    const theme = createTheme({
        palette: {
            azul: {
                main: '#2e5ca2',
                light: '#2e5ca2',
                dark: '#2e5ca2',
                contrastText: '#EEEEEE'
            },
            naranja: {
                main: '#F9B234',
                light: '#F9B234',
                dark: '#F9B234',
                contrastText: '#111111'
            },
            blanco: {
                main: '#EEEEEE',
                light: '#EEEEEE',
                dark: '#EEEEEE',
                contrastText: '#111111'
            },
            negro: {
                main: '#111111',
                light: '#111111',
                dark: '#111111',
                contrastText: '#FFFFFF'
            },
            verde: {
                main: '#33b13d',
                light: '#33b13d',
                dark: '#33b13d',
                contrastText: '#000000'
            },
            rojo: {
                main: '#cc1111',
                light: '#cc1111',
                dark: '#cc1111',
                contrastText: '#FFFFFF'
            },
            morado: {
                main: '#7f00ff',
                light: '#7f00ff',
                dark: '#7f00ff',
                contrastText: '#FFFFFF'
            }
        }
    });
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    return (
        <div className='load' style={{ minHeight: '100vh' }}>
            <ThemeProvider theme={theme}>
                <Routes>
                    <Route element={<PrivateRoute />}>
                        <Route path='*' element={<Inicio />} ></Route>
                        <Route path='/' element={<Inicio />} ></Route>
                        <Route path='/inicio' element={<Inicio />} ></Route>

                        <Route path='/proyectos' element={<Proyectos />} ></Route>
                        <Route path='/crear-trabajo/:idProyecto' element={<CrearTrabajo />} ></Route>
                        <Route path='/trabajos/:idProyecto' element={<Trabajos />} ></Route>
                        <Route path='/editar-trabajo/:idTrabajo' element={<EditarTrabajo />} ></Route>
                        <Route path='/trabajo-finalizado/:idTrabajo' element={<TrabajoFinalizado />} ></Route>

                        <Route path='/ver-stock' element={<VerStock />} ></Route>
                        <Route path='/editar-stock' element={<EditarStock />} ></Route>
                        <Route path='/editar-stock/:codBarras' element={<EditarStock />} ></Route>
                        <Route path='/anadir-articulo/:codBarras' element={<AnadirStock />} ></Route>
                        <Route path='/anadir-articulo' element={<AnadirStock />} ></Route>
                        {/* fichajes */}
                        <Route path="/fichajes" element={<Fichajes />}></Route>
                        <Route path="/fichajesjeusuario/:id" element={<FichajesUsuario />}></Route>

                        {/* //recursos humanos */}
                        <Route path="/rrhh" element={<RecursosHumanos />}></Route>
                        <Route path="/usuarios" element={<Usuarios />}></Route>

                        {/* //departamentos */}
                        <Route path="/rolesydepartamentos" element={<RolesYDepartamentos />}></Route>

                        {/* //notificaciones */}
                        <Route path="/admin" element={<Admin />}></Route>

                        {/* //usuarios */}
                    </Route>

                    <Route path="*" element={<Navigate to="/" replace />} />
                    <Route path='/login' element={<Login username={username} setUsername={setUsername} password={password} setPassword={setPassword} />} ></Route>
                </Routes>
            </ThemeProvider>

            <Footer></Footer>
        </div>
    )
}
