import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import axios from 'axios';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';

import GroupIcon from '@mui/icons-material/Group';

import { Header } from '../components/Header';
import { facturarTrabajo, getEditarTrabajo, getEmpleadosAsig } from '../services/apis';
import { SubHeader } from '../components/SubHeader';
import { CerrarSesion } from '../components/Botones/CerrarSesion';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export const TrabajoFinalizado = () => {
    const param = useParams();
    const Navigate = useNavigate();

    const [articulos, setArticulos] = useState([]);
    const [trabajo, setTrabajo] = useState([]);
    const [open, setOpen] = useState(false);
    const [infoTrabajo, setInfoTrabajo] = useState([]);
    const [empleadosAsig, setEmpleadosAsig] = useState([])


    // const cambiarFormatoFecha = (fechaCompleta) => {
    //     // Dividimos la parte de la fecha y la hora
    //     const [fechaISO, hora] = fechaCompleta.split(' ');

    //     // Dividimos la fecha ISO en [año, mes, día]
    //     const [year, month, day] = fechaISO.split('-');

    //     // Creamos el nuevo formato de fecha "día/mes/año"
    //     const fechaFormateada = `${day}/${month}/${year}`;

    //     // Retornamos el nuevo formato completo: "fecha ISO fecha formateada - hora"
    //     return `${fechaFormateada} - ${hora}`;
    // };



    const getTrabajo = async (idTrabajo) => {
        try {
            const response = await getEditarTrabajo(idTrabajo);
            setArticulos(response.data)
            setInfoTrabajo(response.info[0])
            //console.log("DATA", response);

            if (response.info[0].estado === "Iniciado" || response.info[0].estado === "Pausado") {
                Navigate("/editar-trabajo/" + idTrabajo)
            }

        } catch (error) {
            //console.log("Error al añadir a la Cesta")
        }
    }

    const cargarEmpleadosAsig = async (idTrabajo) => {
        try {
            const response = await getEmpleadosAsig(idTrabajo);
            // //console.log(response.data);

            if (response.success) {
                setEmpleadosAsig(response.data);
            }

        } catch (error) {
            console.error("Error:", error);
        }
    };



    const handleClose = () => {
        setOpen(null);
    };

    const finalizar = async (id, idProyecto) => {
        try {
            const response = await facturarTrabajo(3, id, idProyecto);
            //console.log("aa", response)
            setOpen(false)
            Navigate("/trabajos/" + idProyecto)
        } catch (error) {
            console.error("Error al añadir el cliente:", error);
        }
    }



    useEffect(() => {
        getTrabajo(param.idTrabajo);
        cargarEmpleadosAsig(param.idTrabajo);

    }, [])


    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            xs={12}
            className='load'
            sx={{ pb: 10 }}
        >
            <Header></Header>

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                xs={11} md={10}
            >
                <SubHeader></SubHeader>
            </Grid>

            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                xs={11} md={10}
                className='borde-redondo sombra-corta'
                padding={2}
                gap={1}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    md={11}
                >
                    <Grid item xs={11} md={12} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <GroupIcon sx={{ fontSize: 50, m: 1 }} color='negro' />
                        <Typography variant="h4" style={{ textAlign: 'left' }}>
                            Trabajo Nº: {param.idTrabajo}
                        </Typography>
                    </Grid>
                    <div className="separador"></div>
                </Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent="start"
                    alignItems="start"
                    xs={11}
                >
                    <Grid item xs={12}>
                        <Typography variant="h5" style={{ textAlign: 'left' }} gutterBottom>
                            {infoTrabajo.nombre}
                        </Typography>
                    </Grid>

                    <Grid item xs={1}>
                        <div className="separador-azul"></div>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="body1" style={{ textAlign: 'left' }}>
                            {infoTrabajo.descripcion}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    md={11}
                >
                    <Typography variant="h6" style={{ textAlign: 'left' }}>
                        Horas Totales: {infoTrabajo.horasTotales}h
                    </Typography>
                    <Grid item xs={12} md={5}>
                        <Typography variant="h6" sx={{ textAlign: { xs: 'left', md: 'right' } }}>
                            Fecha creacion: {infoTrabajo.fecha}
                        </Typography>
                        {/* <Typography variant="h6" style={{ textAlign: 'right' }}>
                            Creado por: {infoTrabajo.idUsuario}
                        </Typography> */}
                    </Grid>

                    <div className="separador"></div>

                </Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    md={11}
                >
                    <Grid item xs={12}>
                        <Typography variant="h5" style={{ textAlign: 'left' }}>
                            Articulos usados
                        </Typography>
                    </Grid>
                    <Grid item xs={1} style={{ marginTop: '1em' }}>
                        <div className="separador-azul"></div>
                    </Grid>
                </Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    md={11}
                    gap={1}
                    style={{ maxHeight: '50vh', overflow: 'auto', padding: '0.1em' }}
                >
                    {articulos.map((info, index) => (
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-start"
                            md={12}
                            gap={2}
                        >
                            <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                md={12}
                                gap={1}
                            >
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="start"
                                    alignItems="start"
                                    xs={8}
                                >
                                    <Typography variant="h5" style={{ textAlign: 'left' }}>
                                        {info[0].nombre}
                                    </Typography>
                                    <Typography variant="subtitle2" style={{ textAlign: 'left' }}>
                                        Codigo de barras: {info[0].codBarras}
                                    </Typography>
                                </Grid>

                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="center"
                                    alignItems={{ xs: 'start', md: 'end' }}
                                    xs={12} md={3.5}
                                >
                                    <Typography variant="subtitle1" style={{ textAlign: 'right' }}>
                                        Cantidad usada del almacen {info[0].almacen}: {info[0].cantidad}
                                    </Typography>
                                    <Typography variant="subtitle1" style={{ textAlign: 'right' }}>
                                        Cantidad usada del almacen {info[1].almacen}: {info[1].cantidad}
                                    </Typography>
                                </Grid>

                            </Grid>

                            <div className="separador"></div>

                        </Grid>
                    ))}
                </Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent="start"
                    alignItems="start"
                    xs={11}
                >
                    <Grid item xs={12}>
                        <Typography variant="h5" style={{ textAlign: 'left' }} gutterBottom>
                            Empleados asignados
                        </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <div className="separador-azul"></div>
                    </Grid>
                </Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    xs={11}
                    gap={2}
                >
                    {empleadosAsig.map((emp, index) => (
                        <Grid
                            container
                            direction="row"
                            justifyContent={{ xs: 'center', md: 'space-between' }}
                            alignItems="center"
                            xs={12}
                        >
                            <Grid item xs={12} md={6}>
                                <Typography variant="h6" style={{ textAlign: 'left' }}>
                                    {emp.nombre} {emp.apellidos}
                                </Typography>
                            </Grid>

                            <Grid item xs={12} md={2}>
                                <Typography variant="subtitle1" sx={{
                                    textAlign: { xs: 'left', md: 'right' } // Estilo responsivo directo
                                }}>
                                    Horas empleadas: {emp.horas}h
                                </Typography>
                            </Grid>

                            <div className="separador"></div>
                        </Grid>
                    ))}
                </Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    xs={11}
                    gap={1}
                >

                    {infoTrabajo.id_estado != 3 ?
                        <>
                            <Button fullWidth variant='contained' color='azul' size='large' sx={{ pt: 1.7, pb: 1.7, mt: 2 }} onClick={() => setOpen(true)}> Exportar</Button>
                        </>
                        :
                        <>
                            <Button disabled fullWidth variant='contained' color='azul' size='large' sx={{ pt: 1.7, pb: 1.7, mt: 2 }} onClick={() => setOpen(true)}> Este trabajo ya ha sido exportado.</Button>
                        </>}
                </Grid>

            </Grid>


            <>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth
                    TransitionComponent={Transition}
                >
                    <DialogContent>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            xs={12}
                            gap={2}
                        >
                            <Grid container xs={12}
                                direction="column"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >
                                <Typography variant="h4" gutterBottom>
                                    Exportar trabajo Nº {param.idTrabajo}
                                </Typography>
                                <Typography variant="subtitle1" gutterBottom className='texto-gris'>
                                    Esta seguro que desea exportar el trabajo  Nº {param.idTrabajo}?
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            xs={12}
                        >
                            <Button variant="outlined" sx={{ mt: 2, p: 1 }} onClick={() => handleClose()}> Cancelar</Button>
                            <Button variant="contained" sx={{ mt: 2, p: 1 }} onClick={() => finalizar(param.idTrabajo, infoTrabajo.idProyecto)}> Exportar trabajo</Button>

                        </Grid>



                    </DialogContent>
                </Dialog>

            </>
            <CerrarSesion></CerrarSesion>
        </Grid >
    )
}
