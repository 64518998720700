import { Button, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import { useNavigate } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import { OpcionesMenuNav } from '../../utils/componentes-utiles/OpcionesMenuNav';
import { SubHeader } from '../SubHeader';
import { getOpcionesAdmin } from '../../services/apis';
import { Header } from '../Header';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

export const Admin = () => {
    const Navigate = useNavigate();

    const [opciones, setOpciones] = useState([]);
    const [loaded, setLoaded] = useState([]);

    const cargarOpciones = async () => {
        try {
            const response = await getOpcionesAdmin();
            //console.log(response.data)
            setOpciones(response.data)
            setLoaded(true);
        } catch (error) {
            console.error("Error al añadir el cliente:", error);
        }
    };

    useEffect(() => {
        cargarOpciones();
    }, []);

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            xs={12}
            className='load'
        >
            <Header></Header>

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                xs={8}
                sx={{ mb: 3, mt: 2 }}
            >
                <SubHeader></SubHeader>

            </Grid>

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                xs={11} md={8}
            >
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <AdminPanelSettingsIcon sx={{ fontSize: 50, mt: -3 }} color='error' />
                    <Typography variant="h4" gutterBottom>
                        Administrador
                    </Typography>
                </Grid>


                <div className="separador"></div>

                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    xs={12}
                >
                    <OpcionesMenuNav opciones={opciones}></OpcionesMenuNav>

                </Grid>
            </Grid>




        </Grid>
    )
}
