import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';


import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';


import { Header } from '../components/Header';
import { CerrarSesion } from '../components/Botones/CerrarSesion';
import { Camara } from '../components/Camara';
import { getOpcionesFormStock, postAriculo } from '../services/apis';
import { SubHeader } from '../components/SubHeader';
import { PercioVentaPrecioCompra } from '../utils/componentes-utiles/PercioVentaPrecioCompra';

export const AnadirStock = () => {
    const Navigate = useNavigate();
    const param = useParams();
    const [articulo, setArticulo] = useState([
        {
            nombre: '',
            codBarras: '',
            codigoGw: '',
            precio: null,
            precioCompra: null,
            almacen: 1,
            pasillo: null,
            balda: null,
            stock: 0,
            cantidad: 0
        },
        {
            nombre: '',
            codBarras: '',
            codigoGw: '',
            precio: null,
            precioCompra: null,
            almacen: 2,
            pasillo: null,
            balda: null,
            stock: 0,
            cantidad: 0
        },
    ]);

    const [correcto, setCorrecto] = useState(false);

    const [camara, setCamara] = useState(false);
    const [barcode, setBarcode] = useState(null);


    const handleClose = () => {
        setCorrecto(false);
        setArticulo([
            {
                nombre: '',
                codBarras: '',
                codigoGw: '',
                precio: null,
                precioCompra: null,
                almacen: 1,
                pasillo: null,
                balda: null,
                stock: 0,
                cantidad: 0
            },
            {
                nombre: '',
                codBarras: '',
                codigoGw: '',
                precio: null,
                precioCompra: null,
                almacen: 2,
                pasillo: null,
                balda: null,
                stock: 0,
                cantidad: 0
            },
        ])
    };

        const [opciones, setOpciones] = useState([]);
    
    const handledOnChange = (event, index) => {
        const { name, value } = event.target; // Extraer el nombre y el valor del evento

        setArticulo(prevArticulo => {
            // Hacer una copia del array para mantener la inmutabilidad
            const nuevoArticulo = [...prevArticulo];

            // Modificar el primer elemento del array
            if (nuevoArticulo[0]) {
                switch (name) {
                    case "codBarras":
                        nuevoArticulo[0].codBarras = value;
                        nuevoArticulo[1].codBarras = value;
                        break;
                    case "codigoGw":
                        nuevoArticulo[0].codigoGw = value;
                        nuevoArticulo[1].codigoGw = value;
                        break;
                    case "nombre":
                        nuevoArticulo[0].nombre = value;
                        nuevoArticulo[1].nombre = value;
                        break;
                    case "precio":
                        if (value < 0.00) {
                            nuevoArticulo[0].precio = 0.00;
                            nuevoArticulo[1].precio = 0.00;
                        } else {
                            nuevoArticulo[0].precio = value;
                            nuevoArticulo[1].precio = value;
                        }

                        break;
                    case "precioCompra":
                        if (value < 0.00) {
                            nuevoArticulo[0].precioCompra = 0.00;
                            nuevoArticulo[1].precioCompra = 0.00;
                        } else {
                            nuevoArticulo[0].precioCompra = value;
                            nuevoArticulo[1].precioCompra = value;
                        }

                        break;
                    case "stock":
                        if (value < 0) {
                            nuevoArticulo[index].stock = 0;
                        } else {
                            nuevoArticulo[index].stock = value;
                        }
                        break;
                    default:
                        break;
                }
            }
            return nuevoArticulo;
        });
    };


    const anadirArticulo = async (articulo) => {
        try {
            const response = await postAriculo(articulo);
            //console.log("JSX", response)

            if (response.success) {
                setCorrecto(true)
                setArticulo(response.articulo)
            }

        } catch (error) {
            console.error("Error:", error);
        }
    }

    
        const cargarOpcionesForm = async () => {
            try {
                const response = await getOpcionesFormStock();
                if (response.success) {
                    setOpciones(response.data)
                    //console.log(response.data);
    
                }
            } catch (error) {
                console.error("Error al cargar opciones romulario:", error);
                // setError(true);
            }
        }


    useEffect(() => {
        //console.log(param.codBarras);
        cargarOpcionesForm();
        if (param.codBarras != null) {
            setArticulo([
                {
                    nombre: '',
                    codBarras: param.codBarras,
                    codigoGw: '',
                    precio: null,
                    precioCompra: null,
                    almacen: 1,
                    pasillo: null,
                    balda: null,
                    stock: 0,
                    cantidad: 0
                },
                {
                    nombre: '',
                    codBarras: param.codBarras,
                    codigoGw: '',
                    precio: null,
                    precioCompra: null,
                    almacen: 2,
                    pasillo: null,
                    balda: null,
                    stock: 0,
                    cantidad: 0
                },
            ]);
        }
    }, [])
  const iconMapping = {
        PercioVentaPrecioCompra: <PercioVentaPrecioCompra articulo={articulo} handledOnChange={handledOnChange}></PercioVentaPrecioCompra>
    };

    return (
        <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="flex-start"
            xs={12}
            className='load'
            gap={2}
            sx={{ pb: 10 }}
        >
            <Header></Header>

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                xs={11} sm={11} md={10} xl={8}
            >
                <SubHeader></SubHeader>

            </Grid>

            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                xs={11} sm={11} md={10} xl={8}
                className='borde-redondo sombra-corta'
                padding={2}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    md={11}
                >
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <LibraryAddIcon sx={{ fontSize: 50, m: 1 }} color='verde' />
                        <Typography variant="h4">
                            Añadir Articulo
                        </Typography>
                    </Grid>


                    <div className="separador"></div>
                </Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="start"
                    xs={12}
                    gap={1}
                >
                    <Grid
                        container
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        xs={12} md={5}
                        gap={0.5}
                    >
                        <Typography variant="h5" style={{ textAlign: 'left' }}>
                            Escanear codigo de barras
                        </Typography>
                        <Button color='azul' variant="outlined" size="large" fullWidth onClick={() => camara ? setCamara(false) : setCamara(true)} style={{ padding: '0.9em' }}>
                            <CameraAltIcon color='azul' />&nbsp;{camara ? 'Cerrar camara' : 'Abrir camara'}
                        </Button>
                        <Camara
                            camara={camara}
                            setCamara={setCamara}
                            barcode={barcode}
                            setBarcode={setBarcode}
                            // buscarArticulo={buscarArticulo}
                            articulo={articulo}
                            setArticulo={setArticulo}
                        ></Camara>

                        <Typography variant="h5" style={{ textAlign: 'left' }}>
                            Codigo de barras
                        </Typography>

                        <TextField value={articulo[0].codBarras} name='codBarras' type='text' id="outlined-basic" label="Codigo de barras" variant="outlined" fullWidth color='azul' onChange={handledOnChange} />

                        <div className="separador-2"></div>


                        <Typography variant="h5" style={{ textAlign: 'left' }}>
                            Codigo de Gestwin
                        </Typography>

                        <TextField value={articulo[0].codigoGw} name='codigoGw' id="outlined-basic" label="Codigo de Gestwin" variant="outlined" fullWidth color='azul' onChange={handledOnChange} />

                        <div className="separador-2"></div>

                        <Typography variant="h5" style={{ textAlign: 'left' }}>
                            Nombre del articulo:
                        </Typography>

                        <TextField value={articulo[0].nombre} name='nombre' id="outlined-basic" label="Nombre del articulo" variant="outlined" fullWidth color='azul' onChange={handledOnChange} />

                        <div className="separador-2"></div>
                    </Grid>

                    <Grid
                        container
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        xs={12} md={5}
                        gap={0.5}
                    >
                        {/* <Typography variant="h5" style={{ textAlign: 'left' }}>
                            Precio Compra (€)
                        </Typography>

                        <TextField value={articulo[0].precioCompra} name='precioCompra' id="outlined-basic" label="Precio" variant="outlined" fullWidth color='azul' type='number' onChange={handledOnChange} inputProps={{ step: 0.01 }} />

                        <div className="separador-2"></div>

                        <Typography variant="h5" style={{ textAlign: 'left' }}>
                            Precio Venta (€)
                        </Typography> */}
                        {opciones.length >= 1 && (
                            <>
                                {opciones.map((opcion, index) => (
                                    <React.Fragment key={index}>
                                        {iconMapping[opcion.icon] || null}
                                    </React.Fragment>
                                ))}
                            </>
                        )}


                        {/* <TextField value={articulo[0].precio} name='precio' id="outlined-basic" label="Precio" variant="outlined" fullWidth color='azul' type='number' onChange={handledOnChange} inputProps={{ step: 0.01 }} /> */}

                        <div className="separador-2"></div>

                        <Typography variant="h5" style={{ textAlign: 'left' }}>
                            Stock inicial
                        </Typography>

                        <div className="separador"></div>

                        {articulo.map((info, index) => (
                            <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                xs={12}
                                gap={1}
                            >
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="start"
                                    xs={12}
                                >
                                    <Typography variant="h5" style={{ textAlign: 'left' }}>
                                        Almacen {index + 1}:
                                    </Typography>

                                    <TextField
                                        fullWidth
                                        id="outlined-basic"
                                        // label="Cantidad"
                                        variant="outlined"
                                        color='azul'
                                        type='number'
                                        name='stock'
                                        value={info.stock}
                                        onChange={(event) => handledOnChange(event, index)}
                                        sx={{ marginTop: '0.5em' }}
                                    />
                                </Grid>


                                <Grid item xs={12} md={12} style={{ textAlign: 'left', marginTop: '0em' }}>
                                    <Typography variant="caption" className='texto-gris'>
                                        Escribe la cantidad de stock inicial del almacen {index + 1}.
                                    </Typography>
                                </Grid>
                                {/* {index < articulo.length ? <div className="separador-2" style={{ margin: '0.95em 0em' }}></div> : ''} */}

                            </Grid>
                        ))}

                    </Grid>

                    {/* <div className="separador-2"></div> */}
                    <Grid item xs={12} md={11}>
                        <div className="separador" style={{ margin: '0.95em 0em' }}></div>

                    </Grid>

                    <Grid item xs={12} md={5}>
                        <Button variant="outlined" size="large" color='azul' fullWidth sx={{ p: 1.5 }} onClick={() => Navigate("/")}> Cancelar </Button>
                    </Grid>

                    <Grid item xs={12} md={5}>
                        <Button variant="contained" size="large" color='azul' fullWidth sx={{ p: 1.5 }} onClick={() => anadirArticulo(articulo)}> Añadir Stock </Button>
                    </Grid>
                </Grid>

            </Grid>


            <Dialog
                open={correcto}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        xs={12}
                    >
                        <Typography variant="h4" style={{ textAlign: 'left' }}>
                            <CheckCircleOutlineIcon color='success' sx={{ fontSize: 40, mb: -0.8 }} /> Articulo añadido con exito!
                        </Typography>
                    </Grid>

                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Grid
                            container
                            direction="column"
                            justifyContent="start"
                            alignItems="start"
                            xs={12}
                            gap={0.5}
                        >
                            <Typography variant="h5" style={{ textAlign: 'left' }}>
                                Nombre: {articulo[0].nombre}
                            </Typography>
                            <Typography variant="body1" style={{ textAlign: 'left' }}>
                                Codigo de barras: {articulo[0].codBarras}
                            </Typography>
                            <Typography variant="body1" style={{ textAlign: 'left' }}>
                                Codigo de referencia: {articulo[0].codigoGw}
                            </Typography>
                            <Typography variant="h6" style={{ textAlign: 'left' }}>
                                Precio Compra: {articulo[0].precioCompra}€
                            </Typography>
                            <Typography variant="h6" style={{ textAlign: 'left' }}>
                                Precio: {articulo[0].precio}€
                            </Typography>

                            <div className="separador"></div>

                            <Typography variant="h6" style={{ textAlign: 'left' }}>
                                Stock inicial:
                            </Typography>
                            <Typography variant="subtitle1" style={{ textAlign: 'left' }}>
                                Almacen: {articulo[0].stock}
                            </Typography>
                            <Typography variant="subtitle1" style={{ textAlign: 'left' }}>
                                Almacen: {articulo[1].stock}
                            </Typography>

                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        fullWidth
                        sx={{ m: 1 }}
                        size='large'
                        variant="contained"
                        onClick={() => {
                            handleClose();
                            setArticulo([
                                {
                                    nombre: '',
                                    codBarras: '',
                                    codigoGw: '',
                                    precio: '',
                                    precioCompra: '',
                                    almacen: 1,
                                    pasillo: null,
                                    balda: null,
                                    stock: 0,
                                    cantidad: 0
                                },
                                {
                                    nombre: '',
                                    codBarras: '',
                                    codigoGw: '',
                                    precio: '',
                                    precioCompra: '',
                                    almacen: 2,
                                    pasillo: null,
                                    balda: null,
                                    stock: 0,
                                    cantidad: 0
                                },
                            ])
                        }}
                        autoFocus
                    >
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>



            <CerrarSesion></CerrarSesion>
        </Grid>
    )
}
