import React, { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';

import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { DialogFinalizar } from './DialogFinalizar';
import { finalizarProyecto } from '../services/apis';



const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export const ListaProyectos = ({ proyectos, finalizado, setProyectos }) => {

    const Navigate = useNavigate();

    const [open, setOpen] = useState(null);
    const [open2, setOpen2] = useState(null);


    const handleClickOpen = (index) => {
        setOpen(index);
    };


    const cambiarFormatoFecha = (fechaCompleta) => {
        // Dividimos la parte de la fecha y la hora
        const [fechaISO, hora] = fechaCompleta.split(' ');

        // Dividimos la fecha ISO en [año, mes, día]
        const [year, month, day] = fechaISO.split('-');

        // Creamos el nuevo formato de fecha "día/mes/año"
        const fechaFormateada = `${day}/${month}/${year}`;

        // Retornamos el nuevo formato completo: "fecha ISO fecha formateada - hora"
        return `${fechaFormateada}`;
    };
    const getCardStyle = (estado) => {
        switch (estado) {
            case "2":
                return { backgroundColor: '#c9f7cb' };
            case "0":
                return { backgroundColor: '#FFFFFF' };
            case "1":
                return { backgroundColor: '#f7cbcb' };
            case "3":
                return { backgroundColor: '#e1cbf7' };
            default:
                return { backgroundColor: 'transparent' };
        }
    };

    const handleClose = () => {
        setOpen(null);
        setOpen2(null);

    };

    const finalizar = async (id) => {
        try {
            const response = await finalizarProyecto(2, id);
            //console.log("aa", response)
            if (response.success) {
                setOpen(null);
                setProyectos(response.proyectos)
            }
        } catch (error) {
            console.error("Error al añadir el cliente:", error);
        }
    }


    const facturar = async (id) => {
        try {
            const response = await finalizarProyecto(3, id);
            //console.log("aa", response)
            if (response.success) {
                setOpen2(null);

            }

            // setProyectos(response.data)
        } catch (error) {
            console.error("Error al añadir el cliente:", error);
        }
    }

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            xs={11}
            gap={2}
            className='load fondo-blanco'
        >
            {proyectos.map((info, index) => (
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    xs={12} md={3.5}
                    className='borde-redondo sombra-corta card-articulo load'
                    padding={2}
                    key={info.id}
                    style={getCardStyle(info.id_estado)}
                // onClick={() => Navigate("/trabajos/" + info.id)}
                >
                    <Grid item xs={info.finalizado == 1 ? 10 : 12} onClick={() => Navigate("/trabajos/" + info.idProyecto)} className='pointer'>
                        <Typography variant="h5" style={{ textAlign: 'left', marginBottom: '0.5em', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            {info.nombre}
                        </Typography>
                    </Grid>
                    {info.finalizado == 1 ?
                        <Grid item xs={2} className='no-select'>
                            <Tooltip
                                title="Finalizado"
                                placement="top" color='error'
                                arrow
                                TransitionComponent={Zoom}
                            >
                                <CheckCircleIcon color='success' fontSize='medium' />
                            </Tooltip>
                        </Grid>
                        : ''}
                    <div className="separador-azul" style={{ marginBottom: '0.5em' }}></div>

                    <Grid item xs={12} style={{ textAlign: 'left', marginBottom: '0.5em' }}>
                        <Typography variant="caption" style={{ textAlign: 'left', marginBottom: '1em', fontWeight: '400' }}>
                            Fecha de creación: {cambiarFormatoFecha(info.fecha)}
                        </Typography>
                    </Grid>
                    <Grid container xs={12} justifyContent="space-between" alignItems="center">
                        <Typography variant="h6" style={{ textAlign: 'left' }}>
                            Proyecto Nº{info.idProyecto}
                        </Typography>

                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            xs={12} md={12} lg={4.5}
                        >
                            {(() => {
                                switch (info.id_estado) {
                                    case "0":
                                        return (
                                            <ButtonGroup fullWidth variant="outlined" aria-label="Basic button group">
                                                <Button color='azul' onClick={() => Navigate("/trabajos/" + info.idProyecto)} size='small'>Ver</Button>
                                                <Button color='azul' size='small' onClick={() => handleClickOpen(index)}>finalizar</Button>
                                            </ButtonGroup>
                                        );
                                    case "2":
                                        return (
                                            <ButtonGroup fullWidth variant="outlined" aria-label="Basic button group">
                                                <Button variant="outlined" color='azul' onClick={() => Navigate("/trabajos/" + info.idProyecto)} size='small'>Ver</Button>
                                                <Button variant="outlined" color='azul' size='small'>Exportar</Button>
                                            </ButtonGroup>
                                        );
                                    case "3":
                                        return (
                                            <ButtonGroup fullWidth variant="outlined" aria-label="Basic button group">
                                                <Button variant="outlined" color='azul' onClick={() => Navigate("/trabajos/" + info.idProyecto)} size='small'>Ver</Button>
                                            </ButtonGroup>
                                        );
                                    default:
                                        return null; // No mostrar nada si no coincide con ningún caso
                                }
                            })()}
                        </Grid>


                    </Grid>
                    <>
                        <Dialog
                            open={open === index}
                            onClose={handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            // fullScreen
                            fullWidth
                            TransitionComponent={Transition}
                        >
                            <DialogContent>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    xs={12}
                                    gap={2}
                                >
                                    <Grid container xs={12}
                                        direction="column"
                                        justifyContent="flex-start"
                                        alignItems="flex-start"
                                    >
                                        <Typography variant="h4" gutterBottom>
                                            Finalizar proyecto Nº {info.idProyecto}
                                        </Typography>
                                        <Typography variant="subtitle1" gutterBottom className='texto-gris'>
                                            Esta seguro que desea finalizar proyecto  Nº {info.idProyecto}?
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    xs={12}
                                >
                                    <Button variant="outlined" sx={{ mt: 2, p: 1 }} onClick={() => handleClose()}> Cancelar</Button>
                                    <Button variant="contained" sx={{ mt: 2, p: 1 }} onClick={() => finalizar(info.idProyecto)}> Finalizar proyecto</Button>

                                </Grid>



                            </DialogContent>
                        </Dialog>

                    </>

                    <>
                        <Dialog
                            open={open2 === index}
                            onClose={handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            // fullScreen
                            fullWidth
                            TransitionComponent={Transition}
                        >
                            <DialogContent>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    xs={12}
                                    gap={2}
                                >
                                    <Grid container xs={12}
                                        direction="column"
                                        justifyContent="flex-start"
                                        alignItems="flex-start"
                                    >
                                        <Typography variant="h4" gutterBottom>
                                            Exportar proyecto Nº {info.idProyecto}
                                        </Typography>
                                        <Typography variant="subtitle1" gutterBottom className='texto-gris'>
                                            Esta seguro que desea exportar proyecto  Nº {info.idProyecto}?
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    xs={12}
                                >
                                    <Button variant="outlined" sx={{ mt: 2, p: 1 }} onClick={() => handleClose()}> Cancelar</Button>
                                    <Button variant="contained" sx={{ mt: 2, p: 1 }} onClick={() => facturar(info.idProyecto)}> Exportar proyecto</Button>

                                </Grid>



                            </DialogContent>
                        </Dialog>

                    </>
                </Grid>


            ))
            }


        </Grid >
    )
}
