import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';

import WarehouseIcon from '@mui/icons-material/Warehouse';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';

import { Header } from '../components/Header';
import { CerrarSesion } from '../components/Botones/CerrarSesion';
import { getArticulos } from '../services/apis';
import { SubHeader } from '../components/SubHeader';
import { Filtros } from '../components/Filtros';

export const VerStock = () => {
    const Navigate = useNavigate();

    const [articulos, setArticulos] = useState([]);
    const [articulosOriginal, setArticulosOriginal] = useState([]);

    const [currentPage, setCurrentPage] = useState(1); // Estado para la página actual
    const [filtros, setFiltros] = useState(false);
    const [orden, setOrden] = React.useState('');
    const [cargar, setCargar] = useState(true);

    const itemsPerPage = 52; // Máximo de artículos por página



    // Manejador para cambiar de página
    const handlePageChange = (event, value) => {
        setCurrentPage(value);
        handleScrollToTop();
    };

    const handleChange = (event) => {
        setOrden(event.target.value);
        ordenarArticulos(event.target.value);
    };

    const toggleDrawer = () => {
        setFiltros(true);
    };

    const handleScrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth", // Desplazamiento suave
        });
    }

    const ordenarArticulos = (orden) => {
        let temp = [...articulosOriginal];

        switch (orden) {
            case 0:
                setArticulos(temp)
                break;
            case 1:
                // Ordenar por el campo nombre en orden alfabético
                temp.sort((a, b) => {
                    const nombreA = a[1].nombre?.toUpperCase() || ''; // Convertir a mayúsculas para comparación
                    const nombreB = b[1].nombre?.toUpperCase() || '';
                    return nombreA.localeCompare(nombreB); // Ordenar alfabéticamente
                });
                break;

            case 2:
                // Ordenar por el campo nombre en orden alfabético descendente
                temp.sort((a, b) => {
                    const nombreA = a[1].nombre?.toUpperCase() || ''; // Nombre del primer JSON
                    const nombreB = b[1].nombre?.toUpperCase() || ''; // Nombre del primer JSON
                    return nombreB.localeCompare(nombreA); // Ordenar alfabéticamente descendente
                });
                break;

            default:

                break;
        }

        setArticulos(temp);
    }


    const cargarArticulos = async () => {
        try {
            const response = await getArticulos();
            if (response.success) {
                //console.log(response.data);

                setArticulos(response.data);
                setArticulosOriginal(response.data);
                setCargar(false)
            }
        } catch (error) {
            console.error("Error al obtener los artículos:", error);
        }
    };

    useEffect(() => {
        cargarArticulos();
    }, []);

    // Cálculo para dividir el array en páginas
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentArticulos = articulos.slice(indexOfFirstItem, indexOfLastItem);

    return (
        <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="flex-start"
            xs={12}
            className='load'
            gap={2}
            sx={{ pb: 10 }}
        >
            <Header />



            {/* ------------------------------------ARTICULOS--------------------------------- */}
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                xs={11.5} md={10}
                padding={2}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    xs={12}
                >
                    <SubHeader />
                </Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    md={12}
                >
                    <Grid item xs={12} md={4} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <WarehouseIcon sx={{ fontSize: 50, m: 1 }} color='azul' />
                        <Typography variant="h4">
                            Artículos
                        </Typography>
                    </Grid>

                    <Grid
                        container
                        direction="row"
                        justifyContent="end"
                        alignItems="center"
                        xs={12} md={8}
                        gap={2}
                    >
                        <Grid item xs={12} md={3}>
                            <FormControl sx={{ minWidth: '100%' }}>
                                <InputLabel id="demo-simple-select-label">Ordenar por...</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={orden}
                                    label="Ordenar por..."
                                    onChange={handleChange}
                                >
                                    <MenuItem value={0}>Por defecto</MenuItem>
                                    <MenuItem value={1}>Alfabetico ascendente</MenuItem>
                                    <MenuItem value={2}>Alfabetico descendente</MenuItem>
                                    {/* <MenuItem value={3}>Stock ascendente</MenuItem>
                                    <MenuItem value={4}>Stock descendente</MenuItem> */}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Button fullWidth variant='outlined' onClick={() => filtros ? setFiltros(false) : toggleDrawer()} sx={{ p: 1.3 }} color='azul'>
                                {filtros ? <><ToggleOnIcon sx={{ mt: -0.3, mr: 1, fontSize: 35 }} /> Ocultar Filtros</>
                                    : <><ToggleOffIcon sx={{ mt: -0.3, mr: 1, fontSize: 35 }} />Mostrar Filtros</>}

                            </Button>
                        </Grid>
                    </Grid>
                </Grid>

                <div className="separador"></div>

                {/* ------------------------------------FILTROS--------------------------------- */}
                <Filtros articulos={articulos} setArticulos={setArticulos} filtros={filtros} setFiltros={setFiltros} articulosOriginal={articulosOriginal} />

                <div className="separador-2"></div>

                {cargar ? <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    xs={12}
                >
                    <CircularProgress color='azul' />
                </Grid>

                    : <>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            md={12}
                            gap={2}
                        >
                            {currentArticulos.map((info, index) => (
                                <Grid
                                    key={index} // Agrega la clave única
                                    container
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="flex-start"
                                    xs={12} sm={5} md={3.7} xl={2.9}
                                    className='borde-redondo sombra-mini pointer card-articulo'
                                    padding={1.5}
                                >
                                    <Typography variant="h5" gutterBottom
                                        style={{
                                            textAlign: 'left',
                                            overflow: 'hidden',
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            maxWidth: '100%'
                                        }}
                                    >
                                        {info[1].nombre}
                                    </Typography>

                                    <div className="separador-azul"></div>

                                    <Typography variant="body1">
                                        Cod. Barras: {info[1].codBarras}
                                    </Typography>

                                    <Typography variant="body2" gutterBottom>
                                        Cod. Gestwin: {info[1].codigoGw}
                                    </Typography>

                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        xs={12}
                                    >
                                        <Typography variant="subtitle2">
                                            Precio compra: {info[1].precioCompra}€
                                        </Typography>

                                        <Typography variant="subtitle2">
                                            Precio venta: {info[1].precio}€
                                        </Typography>
                                    </Grid>

                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        xs={12}
                                        gap={2}
                                    >
                                        <Typography variant="subtitle2" gutterBottom>
                                            Almacén 1: {info[1].stock}
                                        </Typography>
                                        <Typography variant="subtitle2" gutterBottom>
                                            Almacén 2: {info[2].stock}
                                        </Typography>
                                    </Grid>

                                    <div className="separador"></div>

                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="start"
                                        md={12}
                                    >
                                        <Typography variant="h6" gutterBottom>
                                            Stock total: {info[1].stock + info[2].stock}
                                        </Typography>
                                        <Button variant="contained" color='azul' onClick={() => Navigate("/editar-stock/" + info[1].codBarras)}>Editar</Button>
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>

                        {/* ----------------------------------PAGINACIÓN---------------------------------- */}

                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            xs={12}
                            sx={{ mt: 5 }}
                        >
                            <Pagination
                                count={Math.ceil(articulos.length / itemsPerPage)}
                                page={currentPage}
                                siblingCount={0}
                                onChange={handlePageChange}
                                color="azul"
                                shape="rounded"
                            />
                        </Grid>
                    </>
                }



            </Grid>

            <CerrarSesion />
        </Grid>
    );
};
