import React from 'react';
import { MenuItem, Select, FormControl, InputLabel } from '@mui/material';

export const SelectorMes = ({ mes, setMes }) => {
    const meses = [
        { id: 1, nombre: "Enero" },
        { id: 2, nombre: "Febrero" },
        { id: 3, nombre: "Marzo" },
        { id: 4, nombre: "Abril" },
        { id: 5, nombre: "Mayo" },
        { id: 6, nombre: "Junio" },
        { id: 7, nombre: "Julio" },
        { id: 8, nombre: "Agosto" },
        { id: 9, nombre: "Septiembre" },
        { id: 10, nombre: "Octubre" },
        { id: 11, nombre: "Noviembre" },
        { id: 12, nombre: "Diciembre" },
    ];

    return (
        <FormControl fullWidth>
            <InputLabel id="selector-mes-label">Seleccionar Mes</InputLabel>
            <Select
                labelId="selector-mes-label"
                value={mes} // Se asegura de que el valor coincida
                onChange={(event) => setMes(event.target.value)}
                label="Seleccionar Mes"
            >
                {meses.map((mesItem) => (
                    <MenuItem key={mesItem.id} value={mesItem.id}>
                        {mesItem.nombre}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};
