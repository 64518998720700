import { Button, Grid } from '@mui/material'
import React from 'react'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';

export const OpcionesMenuFunc = ({ opciones, handleEvent }) => {
    const iconMapping = {
        AddCircleIcon: <AddCircleIcon />,
        PersonAddAltIcon: <PersonAddAltIcon />,
        DeleteForeverIcon: <DeleteForeverIcon />,
        EditIcon: <EditIcon />
    };
    return (
        <Grid
            container
            direction="row"
            xs={12}
            justifyContent="space-evenly"
            alignItems="center"
            gap={1}
        // marginBottom={1}
        >
            {opciones && opciones.length >= 1 && (
                opciones.map((opcion, index) => (
                    <Grid
                        xs={12}
                        key={index}
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    // spacing={0} // Usa spacing para controlar el espaciado entre elementos hijos
                    >
                        <Grid item xs={10}>
                            <Button
                                variant='contained'
                                className='button-default'
                                onClick={() => handleEvent(opcion.id_opcion ? opcion.id_opcion : opcion.id)}
                                disabled={false}
                                color='azul'
                                fullWidth
                            >
                                {iconMapping[opcion.icon] && (
                                    <>
                                        {iconMapping[opcion.icon]}&nbsp;
                                    </>
                                )}
                                {opcion.nombre || ""}
                            </Button>
                        </Grid>

                    </Grid>
                ))
            )}
        </Grid>
    )
}
