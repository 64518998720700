import React, { useEffect, useState, useCallback, useRef } from 'react'
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import Webcam from "react-webcam";
import { BrowserMultiFormatReader } from "@zxing/browser";

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import AddBoxIcon from '@mui/icons-material/AddBox';
import CancelIcon from '@mui/icons-material/Cancel';

import { Header } from '../components/Header';
import { asigEmpleados, deleteArticulo, desasignarEmp, getEditarTrabajo, getEmpleados, getEmpleadosAsig, postAriculoTrabajo, updateAriculoTrabajo, updateHoras } from '../services/apis';
import { finalizarTrabajo } from '../services/apis';
import { Notificacion } from '../components/Notificacion';
import { SubHeader } from '../components/SubHeader';
import { CerrarSesion } from '../components/Botones/CerrarSesion';
import { Camara } from '../components/Camara';
import { SelectEstado } from '../components/SelectEstado';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name, personName, theme) {
    return {
        fontWeight: personName.includes(name)
            ? theme.typography.fontWeightMedium
            : theme.typography.fontWeightRegular,
    };
}



export const EditarTrabajo = () => {
    const param = useParams();
    const Navigate = useNavigate();

    const [notificacion, setNotificacion] = useState(false);
    const [mensaje, setMensaje] = useState("");
    const [severity, setSeverity] = useState("success");

    const [finalizar, setFinalizar] = useState(false);
    const [eliminar, setEliminar] = useState(null);
    const [dialogEmpleadoId, setDialogEmpleadoId] = useState(null);
    const [articulos, setArticulos] = useState([]);
    // const [horas, setHoras] = useState(0);
    const [articulo, setArticulo] = useState([
        {
            nombre: "Nuevo articulo",
            codBarras: null,
            alamcen: 1,
            cantidad: null,
        },
        {
            nombre: "Nuevo articulo",
            codBarras: null,
            alamcen: 1,
            cantidad: null,
        },
    ]);
    const [cantidad1, setCantidad1] = useState(0);
    const [cantidad2, setCantidad2] = useState(0);

    const [codBarras, setCodBarras] = useState("");
    const [infoTrabajo, setInfoTrabajo] = useState([]);

    const [openCamara, setOpenCamara] = useState(false);
    const [camara, setCamara] = useState(false);
    const [barcode, setBarcode] = useState(null);
    const [timer, setTimer] = useState(null);

    const [empleados, setEmpleados] = useState([])
    const [empleadosAsig, setEmpleadosAsig] = useState([])
    const [tempEmpleados, setTempEmpleados] = useState([])

    const theme = useTheme();
    const [personName, setPersonName] = React.useState([]);


    // -------------------------------------------------HANDLEDS----------------------------------------
    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setPersonName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handledOnChange = (event, id) => {
        //console.log(event.target.name, id)
        // setCantidad(event.target.value);

        let temp = [...articulos]; // Copia del estado actual de `articulos`
        switch (event.target.name) {
            case "barcode":
                setCodBarras(event.target.value);
                break;

            case "cantidad0":
                setCantidad1(event.target.value);

                temp = temp.map((art) => {
                    // Asegúrate de que art[1] exista antes de intentar actualizar
                    if (art[0] && art[0].codBarras === id) {
                        return [
                            {
                                ...art[0], // Copiar las propiedades del segundo objeto
                                cantidad: event.target.value <= 0 ? 1 : event.target.value // Actualizar la cantidad
                            }, // Mantener el primer objeto sin cambios
                            art[1]
                        ];
                    }
                    return art; // Mantener los demás elementos igual
                });

                setArticulos(temp); // Actualizar el estado con la nueva copia
                break;
            case "cantidad1":
                setCantidad2(event.target.value);


                temp = temp.map((art) => {
                    // Asegúrate de que art[1] exista antes de intentar actualizar
                    if (art[1] && art[1].codBarras === id) {
                        return [
                            art[0], // Mantener el primer objeto sin cambios
                            {
                                ...art[1], // Copiar las propiedades del segundo objeto
                                cantidad: event.target.value <= 0 ? 1 : event.target.value // Actualizar la cantidad
                            }
                        ];
                    }
                    return art; // Mantener los demás elementos igual
                });

                setArticulos(temp); // Actualizar el estado con la nueva copia
                break;
            case "horas":
                setEmpleadosAsig(prevEmpleados => {
                    const temp = prevEmpleados.find(empleado => empleado.id === id);
                    if (temp) {
                        temp.horas = event.target.value < 0 ? 0 : event.target.value; // Controla que horas no sea menor a 0
                    }
                    return [...prevEmpleados]; // Retorna un nuevo array para que React detecte el cambio
                });

                break;

            default:
                break;
        }
    };

    const handleClose = () => {
        setFinalizar(false);
        setEliminar(null);
    };

    const handleOpenDialog = (id) => {
        setDialogEmpleadoId(id); // Establece el id del empleado cuyo diálogo debe abrirse
    };

    const handleCloseDialog = () => {
        setDialogEmpleadoId(null); // Cierra el diálogo estableciendo el estado en null
    };

    // -------------------------------------------------ARTICULO----------------------------------------
    const añadirArticulo = (articulo, cantidad1, cantidad2, codBarras) => {
        // //console.log(articulo, cantidad, codBarras)
        let temp = [...articulos]; // Copia del estado actual de `articulos`

        cantidad1 = parseInt(cantidad1);
        cantidad2 = parseInt(cantidad2);

        let tempArt = [
            {
                nombre: "Nuevo articulo",
                cantidad: cantidad1,
                almacen: 1,
                codBarras: codBarras,
                idTrabajo: param.idTrabajo
            },
            {
                nombre: "Nuevo articulo",
                almacen: 2,
                cantidad: cantidad2,
                codBarras: codBarras,
                idTrabajo: param.idTrabajo
            }
        ]
        //console.log(tempArt);


        if (codBarras !== "") {
            setArticulos((prevArticulos) => {
                // Buscar si ya existe un artículo con el mismo codBarras
                const articuloExistente = prevArticulos.find((item) => item[0].codBarras === codBarras);

                if (articuloExistente) {
                    // Actualizar la cantidad en tempArt y en prevArticulos
                    const nuevaCantidad1 = articuloExistente[0].cantidad + cantidad1;
                    const nuevaCantidad2 = articuloExistente[1].cantidad + cantidad2;

                    if (nuevaCantidad1 + nuevaCantidad2 === 0) {
                        // setMensaje("Introduzca al menos una unidad del articulo");
                        // setSeverity("error")
                        // activarNotificacion();
                    } else {
                        const tempArt = [
                            {
                                nombre: "Nuevo articulo",
                                cantidad: nuevaCantidad1,
                                almacen: 1,
                                codBarras: codBarras,
                                idTrabajo: param.idTrabajo
                            },
                            {
                                nombre: "Nuevo articulo",
                                almacen: 2,
                                cantidad: nuevaCantidad2,
                                codBarras: codBarras,
                                idTrabajo: param.idTrabajo
                            }
                        ];

                        if (actualizarArticuloTrabajo(tempArt)) {
                            return prevArticulos.map((item) =>
                                item[0].codBarras === codBarras
                                    ? [{ ...item[0], cantidad: item[0].cantidad + cantidad1 },
                                    { ...item[1], cantidad: item[1].cantidad + cantidad2 }]// Actualizar cantidad
                                    : item
                            );
                        }
                    }
                } else {
                    if (enviarArticuloTrabajo(tempArt)) {
                        return [...prevArticulos, tempArt];
                    }
                }
            });
            setCantidad1(0);
            setCantidad2(0);
            setMensaje("Articulo añadido");
            setSeverity("success")
            activarNotificacion();
        } else {
            setMensaje("Introduzca un codigo de barras");
            setSeverity("error")
            activarNotificacion();
        }

    };

    const cambiarCantidad = (art, index) => {
        let tempCantidad = Number(art.cantidad);
        // //console.log(art, index);
        if (tempCantidad < 0) {
            tempCantidad = 0;
        } else {
            let temp = [...articulos];

            if (index !== -1) {
                const nuevoProducto = { ...art, cantidad: tempCantidad };
                temp[index] = nuevoProducto;
            }
            // Cancelar el temporizador previo si existe
            if (actualizarArticuloTrabajo(temp[index])) {
                setMensaje("Cantidad actualizada")
                setSeverity("success")
                activarNotificacion();
            }


            setArticulos(temp);
        }
    };

    const enviarArticuloTrabajo = async (articulo) => {
        try {
            const response = await postAriculoTrabajo(articulo);
            // //console.log(response);

            if (response.success) {
                return true;
            } else {
                return false;
            }

        } catch (error) {
            console.error("Error:", error);
        }
    }

    const actualizarArticuloTrabajo = async (articulo) => {
        try {
            const response = await updateAriculoTrabajo(articulo);
            //console.log(response);

            if (response.success) {
                return true;
            } else {
                return false;
            }

        } catch (error) {
            console.error("Error:", error);
        }
    }

    const eliminarArticulo = async (codBarras, idTrabajo) => {

        try {
            const response = await deleteArticulo(codBarras, idTrabajo);
            //console.log(response);

            if (response) {
                setArticulos(prevArticulos => {
                    // Filtra el array para eliminar el artículo con el codBarras especificado
                    const updatedArticulos = prevArticulos.filter(item => item[0].codBarras !== codBarras);

                    return updatedArticulos; // Devuelve el array actualizado
                });
            } else {
                //console.log("error");

            }

        } catch (error) {
            console.error("Error:", error);
        }
    }

    // -------------------------------------------------EMPLEADOS----------------------------------------
    const cargarEmpleados = async () => {
        try {
            const response = await getEmpleados();
            // //console.log(response);

            if (response.success) {
                setEmpleados(response.data);
                cargarEmpleadosAsig(response.data, param.idTrabajo);

            }

        } catch (error) {
            console.error("Error:", error);
        }
    };

    const cargarEmpleadosAsig = async (empleados, idTrabajo) => {
        try {
            const response = await getEmpleadosAsig(idTrabajo);
            // //console.log(response.data);

            if (response.success) {
                setEmpleadosAsig(response.data);
                organizarEmpleados(empleados, response.data);

            }

        } catch (error) {
            console.error("Error:", error);
        }
    };

    const añadirEmpleados = (empleadosArray) => {

        if (postEmpAsig(empleadosArray, param.idTrabajo)) {
            setEmpleadosAsig(prevEmpleadosAsig => {
                let nuevosEmpleadosAsignados = [...prevEmpleadosAsig]; // Copiar el array de empleadosAsignados actual
                let nuevosEmpleados = [...empleados]; // Copiar el array de empleados actual

                // Iterar sobre el array de empleados a añadir
                empleadosArray.forEach(empleado => {
                    // Buscar el empleado en el array de empleados por id
                    const index = nuevosEmpleados.findIndex(emp => emp.id === empleado.id);

                    if (index !== -1) {
                        // Eliminar el empleado de empleados (Array de empleados)
                        const [empleadoAEliminar] = nuevosEmpleados.splice(index, 1);

                        // Añadir el empleado al array de empleadosAsignados
                        nuevosEmpleadosAsignados = [...nuevosEmpleadosAsignados, empleadoAEliminar];
                    }
                });

                // Actualizar el estado de empleados y empleadosAsignados
                setEmpleados(nuevosEmpleados);
                return nuevosEmpleadosAsignados;
            });
            setTempEmpleados([]);
            setPersonName([]);
        } else {
            //nada
        }

    };
    const postEmpAsig = async (empleados, idTrabajo) => {
        try {
            const response = await asigEmpleados(empleados, idTrabajo);
            // //console.log(response);

            if (response.success) {
                // //console.log(response.success);
                return response.success;
            }

        } catch (error) {
            console.error("Error:", error);
        }
    };

    const quitarEmpleado = (id, idEmpleado, idTrabajo) => {
        //console.log("quitarEmpleadoquitarEmpleado", idEmpleado, idTrabajo);

        setEmpleados(prevEmpleados => {
            // Buscar el empleado en el array de empleadosAsignados por id
            const empleado = empleadosAsig.find(emp => emp.id === id);

            if (empleado) {
                // Eliminar el empleado de empleadosAsignados
                const nuevosEmpleadosAsignados = empleadosAsig.filter(emp => emp.id !== id);

                // Añadir el empleado al array de empleados (Array de empleados)
                const nuevosEmpleados = [...prevEmpleados, empleado].sort((a, b) => a.id - b.id);

                // Actualizar ambos arrays
                setEmpleadosAsig(nuevosEmpleadosAsignados);
                return nuevosEmpleados;
            }

            return prevEmpleados;  // Retorna el array sin cambios si no se encuentra el empleado
        });

        if (!idEmpleado) {
            //console.log("es null");
            desasigEmpleado(id, idTrabajo);

        } else {
            //console.log("No es null");
            desasigEmpleado(idEmpleado, idTrabajo);

        }

    };
    const desasigEmpleado = async (idUsuario, idTrabajo) => {

        let temp = { idUsuario: idUsuario, idTrabajo: idTrabajo };
        try {
            const response = await desasignarEmp(temp);
            // //console.log(response);

            if (response.success) {

            }

        } catch (error) {
            console.error("Error:", error);
        }
    }

    const organizarEmpleados = (empleados, empleadosAsig) => {
        // //console.log(empleados, empleadosAsig);

        // Filtrar el array de empleados, dejando solo aquellos cuyos IDs no están en empleadosAsig
        let temp = empleados.filter(empleado =>
            !empleadosAsig.some(empleadoAsig => empleadoAsig.idUsuario === empleado.id)
        );
        setEmpleados(temp);
    };

    const cambiarHoras = async (trabajador, idTrabajo) => {
        //console.log(trabajador, idTrabajo);

        try {
            const response = await updateHoras(trabajador, idTrabajo);
            //console.log("RESPONSE", response);

            // if (response.success) {
            //     //console.log(response);

            // }

        } catch (error) {
            console.error("Error:", error);
        }
    }

    // -------------------------------------------------VARIOS----------------------------------------
    const activarNotificacion = () => {
        // setMensaje(texto);
        // Cambiar la notificación a true
        setNotificacion(true);

        // Después de 2 segundos, volverla a false
        setTimeout(() => {
            setNotificacion(false);
        }, 3000);
    };

    const abrirCamara = (openCamara, camara) => {

        if (openCamara == false && camara == false) {
            setOpenCamara(true);
            setCamara(true);
        } else {
            setOpenCamara(false);
            if (timer) {
                clearTimeout(timer);
            }

            // Establecer un nuevo temporizador para ejecutar la función después de 2000ms (2 segundos)
            setTimer(setTimeout(() => {
                setCamara(false);

            }, 2000));
        }

    };


    // -------------------------------------------------TRABAJO----------------------------------------
    const getTrabajo = async (idTrabajo) => {
        try {
            const response = await getEditarTrabajo(idTrabajo);
            setArticulos(response.data)
            setInfoTrabajo(response.info[0])
            // //console.log("DATA", response.data);

            if (response.info[0].estado === "Finalizado") {
                Navigate("/trabajo-finalizado/" + idTrabajo)
            }

        } catch (error) {
            //console.log("Error al añadir a la Cesta")
        }

        try {

        } catch (error) {
            console.error("Error inesperado")
        }

    }

    const finTrabajo = async (idTrabajo, articulos, empleadoAsig) => {
        //console.log("AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA", idTrabajo, articulos, empleadoAsig)

        try {
            const response = await finalizarTrabajo(idTrabajo, articulos, empleadoAsig);
            //console.log("Respuesta", response)

            if (response.success) {
                Navigate("/trabajos/" + response.idProyecto[0].idProyecto)

            } else {
                //nada
            }
        } catch (error) {
            //console.log("Error al añadir a la Cesta")
        }
    }


    useEffect(() => {
        getTrabajo(param.idTrabajo);
        cargarEmpleados();

    }, [])

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            xs={12}
            className='load'
            sx={{ pb: 10 }}
        >
            <Header></Header>

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                xs={11} md={10}
            >
                <SubHeader></SubHeader>
            </Grid>

            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                xs={11} md={10}
                className='borde-redondo sombra-corta'
                padding={2}
                gap={1}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    md={11}
                >
                    <Grid item xs={11} md={12} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>

                    </Grid>

                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        xs={12}
                    >
                        <Grid
                            container
                            direction="row"
                            justifyContent="start"
                            alignItems="center"
                            xs={12}
                        // md={6} xl={5}
                        >
                            <GroupAddIcon sx={{ fontSize: 50, m: 1, mt: -0.5 }} color='naranja' />
                            <Typography variant="h4" style={{ textAlign: 'left' }}>
                                Editar Trabajo Nº: {param.idTrabajo}
                            </Typography>
                        </Grid>

                    </Grid>

                    <div className="separador"></div>

                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="start"
                        xs={12}
                    >
                        <Typography variant="h5" style={{ textAlign: 'left' }} gutterBottom>
                            Nombre: {infoTrabajo.nombre}
                        </Typography>
                        <div className="separador-azul"></div>
                        <Typography variant="body1" style={{ textAlign: 'left' }}>
                            {infoTrabajo.descripcion}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '1em' }}>

                        <Typography variant="h6" style={{ textAlign: 'left' }}>
                            Creado: {infoTrabajo.fecha}
                        </Typography>
                        {/* <Typography variant="h5" style={{ textAlign: 'left' }}>
                            Creado por: {infoTrabajo.idUsuario}
                        </Typography> */}
                    </Grid>


                    <div className="separador"></div>
                </Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="start"
                    md={11}
                // spacing={1}
                >

                    <Grid
                        container
                        direction="column"
                        justifyContent="start"
                        alignItems="start"
                        xs={12} md={6}
                    >
                        <Grid item xs={12} >
                            <Typography variant="h5" style={{ textAlign: 'left' }}>
                                Articulos usados:
                            </Typography>
                            <Grid item xs={3} md={4} >
                                <div className="separador-azul" style={{ margin: '0.5em 0em' }}></div>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="start"
                            xs={12}
                            gap={2}
                            style={{ minHeight: '50vh', maxHeight: '60vh', overflow: 'auto', padding: '1em' }}

                        // className='fondo-azul borde-redondo sombra-interior'
                        >
                            {articulos.length > 0 ?
                                <>
                                    {articulos.map((info, index) => (
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="flex-start"
                                            xs={12}
                                            gap={1}
                                            padding={{ xs: 1, md: 2, xl: 3 }}
                                            className='sombra-mini borde-redondo'
                                        >
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="flex-start"
                                                alignItems="flex-start"
                                                md={12}
                                            >
                                                <Grid item xs={12}>
                                                    <Typography variant="h5" style={{ textAlign: 'left' }}>
                                                        <IconButton aria-label="delete" sx={{ mb: 0.3 }} color='rojo'>
                                                            <Tooltip title="Eliminar articulo" arrow >
                                                                <DeleteForeverIcon className='pointer no-select' color='rojo' sx={{ fontSize: 30 }} onClick={() => setEliminar(index)} />
                                                            </Tooltip>
                                                        </IconButton>
                                                        - {info[0].nombre == '' ? 'Articulo nuevo' : info[0].nombre}
                                                    </Typography>

                                                    <Typography variant="subtitle2" style={{ textAlign: 'left' }}>
                                                        Cod. Barras: {info[0].codBarras}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="center"
                                                alignItems="center"
                                                md={12}
                                                sx={{ mt: -1 }}
                                            >
                                                <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                    md={12}
                                                    gap={1}
                                                >
                                                    {info.map((inf, index2) => (
                                                        <>
                                                            <Grid item xs={12} md={12} xl={4}>
                                                                <Typography variant="h6" style={{ textAlign: 'left' }}>
                                                                    Almacen {inf.almacen}:
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12} md={12} xl={6}>
                                                                <TextField
                                                                    fullWidth
                                                                    id="outlined-basic"
                                                                    label="Cantidad"
                                                                    variant="outlined"
                                                                    type='number'
                                                                    value={inf.cantidad}
                                                                    size="small"
                                                                    color="azul"
                                                                    name={'cantidad' + index2}
                                                                    onChange={(event) => handledOnChange(event, inf.codBarras)} />
                                                            </Grid>

                                                        </>
                                                    ))}
                                                    <Grid item xs={12} md={12} xl={12}>
                                                        <Tooltip title="Actualizar cantidad" arrow>
                                                            <Button fullWidth variant='outlined' color='azul' onClick={(event) => cambiarCantidad(info, event, index)}>Actualizar</Button>
                                                        </Tooltip>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            {/* <div className="separador"></div> */}



                                            <Dialog
                                                open={eliminar === index}
                                                onClose={handleClose}
                                                aria-labelledby="alert-dialog-title"
                                                aria-describedby="alert-dialog-description"
                                            >
                                                <DialogTitle
                                                    id="alert-dialog-title"
                                                    className='fondo-blanco'
                                                >
                                                    {"Eliminar articulo"}
                                                </DialogTitle>
                                                <DialogContent
                                                    className='fondo-blanco'
                                                >
                                                    <DialogContentText id="alert-dialog-description">
                                                        Desea eliminar {info[0].nombre}?
                                                    </DialogContentText>
                                                    {/* <DialogContentText id="alert-dialog-description">
                                                        Cantidad del alamacen 1: {info[0].cantidad}
                                                    </DialogContentText>
                                                    <DialogContentText id="alert-dialog-description">
                                                        Cantidad del alamacen 2: {info[1].cantidad}
                                                    </DialogContentText> */}
                                                </DialogContent>
                                                <DialogActions
                                                    className='fondo-blanco'
                                                >
                                                    <Button
                                                        onClick={() => eliminarArticulo(info[0].codBarras, param.idTrabajo)}
                                                        color='rojo' variant="outlined" size="medium">
                                                        Eliminar
                                                    </Button>
                                                    <Button onClick={handleClose} color='azul' size="medium" variant="contained" autoFocus>Cancelar</Button>
                                                </DialogActions>
                                            </Dialog>
                                        </Grid>))}
                                </>
                                :
                                <Typography variant="h5" style={{ textAlign: 'left' }}>
                                    No hay articulos
                                </Typography>}

                        </Grid>
                    </Grid>



                    <Grid
                        container
                        direction="row"
                        justifyContent="start"
                        alignItems="start"
                        md={6}
                        gap={1}
                    >
                        <Grid item xs={12} >
                            <Typography variant="h5" style={{ textAlign: 'left' }}>
                                Añadir articulo:
                            </Typography>
                            <Grid item xs={3} md={1}>
                                <div className="separador-azul" style={{ margin: '0.5em 0em' }}></div>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Button color='azul' variant="outlined" size="large" fullWidth onClick={() => abrirCamara(openCamara, camara)} style={{ margin: '1em 0em', padding: '0.7em' }}>
                                <CameraAltIcon color='azul' />&nbsp;{camara ? 'Cerrar camara' : 'Abrir camara'}
                            </Button>
                        </Grid>

                        <Camara camara={openCamara} setCamara={setCamara} barcode={barcode} setBarcode={setBarcode}></Camara>

                        <FormControl sx={{ width: '100%' }} variant="outlined">
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="outlined-basic"
                                    label="Codigo de Barras"
                                    variant="outlined"
                                    color='azul'
                                    type='text'
                                    autoFocus
                                    required
                                    onChange={handledOnChange}
                                    value={codBarras}
                                    name='barcode'
                                />
                            </Grid>

                            <Grid item xs={12} style={{ marginTop: '1em' }}>
                                <TextField
                                    fullWidth
                                    id="outlined-basic"
                                    label="Cantidad del alamacen 1"
                                    variant="outlined"
                                    color='azul'
                                    type='number'
                                    name='cantidad0'
                                    value={cantidad1}
                                    onChange={handledOnChange}
                                />
                            </Grid>

                            <Grid item xs={12} style={{ marginTop: '1em' }}>
                                <TextField
                                    fullWidth
                                    id="outlined-basic"
                                    label="Cantidad del alamacen 2"
                                    variant="outlined"
                                    color='azul'
                                    type='number'
                                    name='cantidad1'
                                    value={cantidad2}
                                    onChange={handledOnChange}
                                />
                            </Grid>

                            <Button
                                color='azul'
                                variant="contained"
                                size="large"
                                fullWidth
                                style={{ marginTop: '1em', marginBottom: '1em', padding: '1em' }}
                                onClick={() => añadirArticulo(articulo, cantidad1, cantidad2, codBarras)}
                            >
                                <AddBoxIcon color='blanco' />&nbsp;Añadir articulo
                            </Button>
                        </FormControl>

                    </Grid>

                    <div className="separador"></div>
                    <div className="separador-2"></div>

                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        xs={12}
                    >
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            xs={12}
                        >
                            <Typography variant="h5" style={{ textAlign: 'left' }}>
                                Empleados y horas:
                            </Typography>

                            <Grid
                                container
                                direction="row"
                                justifyContent="end"
                                alignItems="center"
                                xs={12} md={6} xl={5}
                                gap={1}
                            >
                                <Grid item xs={12} md={5} xl={6}>
                                    <FormControl sx={{ m: 0, width: '100%' }}>
                                        <InputLabel id="demo-multiple-name-label">Añadir empleados</InputLabel>
                                        <Select
                                            labelId="demo-multiple-name-label"
                                            id="demo-multiple-name"
                                            multiple
                                            value={personName}
                                            onChange={handleChange}
                                            input={<OutlinedInput label="Añadir empleados" />}
                                            MenuProps={MenuProps}
                                        >
                                            {empleados.map((empleado) => (
                                                <MenuItem
                                                    key={empleado.id}
                                                    value={empleado.id}
                                                    style={getStyles(empleado.nombre, personName, theme)}
                                                    onClick={() => tempEmpleados.push(empleado)}
                                                >
                                                    {empleado.nombre + ' ' + empleado.apellidos}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={5} xl={2.5}>
                                    <Button fullWidth color='azul' variant="outlined" size="large" sx={{ pt: 1.7, pb: 1.7 }} onClick={() => añadirEmpleados(tempEmpleados)}><AddIcon />Añadir</Button>

                                </Grid>

                            </Grid>


                        </Grid>
                        <div className="separador-2"></div>
                        <div className="separador"></div>
                        <div className="separador-2"></div>


                    </Grid>
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        xs={12}
                    >
                        <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            xs={12}
                        >
                            <Grid
                                container
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                                xs={12}
                                gap={1}
                            >
                                {empleadosAsig.map((info, index) => (
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        xs={12}
                                        key={index}
                                    >
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="start"
                                            alignItems="center"
                                            xs={12} xl={6}
                                        >
                                            <Grid item xs={2} md={1} xl={1}>
                                                <IconButton aria-label="delete" color='rojo' size="large" sx={{ pt: 1.7, pb: 1.7 }} onClick={() => handleOpenDialog(info.id)}>
                                                    <Tooltip title="Desasignar empleado" arrow >
                                                        <CancelIcon />
                                                    </Tooltip>
                                                </IconButton>
                                            </Grid>

                                            <Grid item xs={7.5} md={8} xl={6}>
                                                <Typography variant="h6" style={{ textAlign: 'left' }}>
                                                    {info.nombre + ' ' + info.apellidos}
                                                </Typography>
                                            </Grid>

                                        </Grid>

                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent={{ xs: 'space-between', md: 'space-between', xl: 'end' }}
                                            alignItems="center"
                                            xs={12} xl={6}
                                            gap={1}
                                        >
                                            <Grid item xs={5.5} md={5.5} xl={3}>
                                                <TextField
                                                    fullWidth
                                                    id="outlined-basic"
                                                    label="Horas"
                                                    variant="outlined"
                                                    color='azul'
                                                    type='number'
                                                    name='horas'
                                                    value={info.horas}
                                                    onChange={(event) => handledOnChange(event, info.id)}
                                                    inputProps={{ step: 0.5 }}
                                                />

                                            </Grid>

                                            <Grid item xs={5.5} md={5.5} xl={3}>
                                                <Button fullWidth color='azul' variant="outlined" size="large" sx={{ pt: 1.7, pb: 1.7 }} onClick={() => cambiarHoras(info, param.idTrabajo)}>
                                                    Actualizar
                                                </Button>
                                            </Grid>


                                        </Grid>

                                        <div className="separador"></div>

                                        <Dialog
                                            open={dialogEmpleadoId === info.id}
                                            onClose={handleClose}
                                            aria-labelledby="alert-dialog-title"
                                            aria-describedby="alert-dialog-description"
                                        >
                                            <DialogTitle
                                                id="alert-dialog-title"
                                                className='fondo-blanco'
                                            >
                                                {"Desasignar empleado"}
                                            </DialogTitle>
                                            <DialogContent
                                                className='fondo-blanco'
                                            >
                                                <DialogContentText id="alert-dialog-description">
                                                    Desea desasignar a {info.nombre + ' ' + info.apellidos} del trabajo?
                                                </DialogContentText>
                                            </DialogContent>
                                            <DialogActions
                                                className='fondo-blanco'
                                            >
                                                <Button onClick={() => handleCloseDialog()} color='azul' size="medium" variant="outlined">Cancelar</Button>
                                                <Button onClick={() => quitarEmpleado(info.id, info.idUsuario, param.idTrabajo)} autoFocus color='azul' variant="contained" size="medium">
                                                    Desasignar
                                                </Button>
                                            </DialogActions>
                                        </Dialog>
                                    </Grid>
                                ))}
                            </Grid>
                            <div className="separador-2"></div>

                        </Grid>

                        <div className="separador-2"></div>
                        <Button color='azul' variant="contained" size="large" fullWidth className='boton-general' onClick={() => setFinalizar(true)}> Finalizar trabajo </Button>

                    </Grid>

                    < Grid item xs={12} >
                    </Grid>
                    <div className="separador-2"></div>
                </Grid>


            </Grid>



            {/* ---------------------------------------------------------------------------NOTIFICACIONES Y DIALOGO----------------------------------------------------------------------------- */}

            <Notificacion notificacion={notificacion} mensaje={mensaje} severity={severity}></Notificacion>


            <Dialog
                open={finalizar}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle
                    id="alert-dialog-title"
                    className='fondo-blanco'
                >
                    {"Finalizar Trabajo"}
                </DialogTitle>
                <DialogContent
                    className='fondo-blanco'
                >
                    <DialogContentText id="alert-dialog-description">
                        Desea finalizar la orden de trabajo?
                    </DialogContentText>
                </DialogContent>
                <DialogActions
                    className='fondo-blanco'
                >
                    <Button onClick={handleClose} color='azul' size="medium" variant="outlined">Cancelar</Button>
                    <Button onClick={() => finTrabajo(param.idTrabajo, articulos, empleadosAsig)} autoFocus color='azul' variant="contained" size="medium">
                        Finalizar Trabajo
                    </Button>
                </DialogActions>
            </Dialog>

            <CerrarSesion></CerrarSesion>
        </Grid>
    )
}
