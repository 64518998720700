import React from 'react'

import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

export const Footer = () => {
    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            xs={12}
            sx={{ mb: 2 }}
            className='no-select'
        >

            <Typography variant='caption' style={{ textAlign: 'center' }}>
                Powered by JC Informatics ©
            </Typography>

            <Typography variant='caption' className='texto-gris' style={{ textAlign: 'center' }}>
                v1.0.2
            </Typography>
        </Grid>
    )
}
