import React, { useState, useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import Webcam from "react-webcam";
import { BrowserMultiFormatReader } from "@zxing/browser";

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';


import SearchIcon from '@mui/icons-material/Search';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';

import { Header } from '../components/Header';
import { CerrarSesion } from '../components/Botones/CerrarSesion';
import { SubHeader } from '../components/SubHeader';
import { Camara } from '../components/Camara';
import { getArticulo, getOpcionesFormStock, updateStock } from '../services/apis';
import { PercioVentaPrecioCompra } from '../utils/componentes-utiles/PercioVentaPrecioCompra';
import { SelectorArticulos } from '../components/SelectorArticulos';



export const EditarStock = () => {

    const Navigate = useNavigate();
    const param = useParams();

    const [error, setError] = useState(false);
    const [editado, setEditado] = useState(false)
    const [timer, setTimer] = useState(null);
    const [cargar, setCargar] = useState(false);
    const [codBarEdit, setCodBarEdit] = useState(true);
    const [errorEdit, setErrorEdit] = useState(false);

    const [camara, setCamara] = useState(false);
    const [barcode, setBarcode] = useState(null);
    const [articulo, setArticulo] = useState([
        {
            nombre: '',
            codBarras: '',
            codigoGw: '',
            precio: null,
            precioCompra: null,
            almacen: null,
            pasillo: null,
            balda: null,
            stock: 0,
            cantidad: 0
        },
        {
            nombre: '',
            codBarras: '',
            codigoGw: '',
            precio: null,
            precioCompra: null,
            almacen: null,
            pasillo: null,
            balda: null,
            stock: 0,
            cantidad: 0
        },
    ]);

    const [nuevoStock, setNuevoStock] = useState([0, 0]);


    const [arrayArticulos, setArrayArticulos] = useState([])
    const [selectArticulos, setSelectArticulos] = useState(false);


    const [opciones, setOpciones] = useState([]);

    const handledOnChange = (event, index) => {

        const { name, value } = event.target; // Extraer el nombre y el valor del evento

        // //console.log(value);

        setArticulo(prevArticulo => {
            // Hacer una copia del array para mantener la inmutabilidad
            const nuevoArticulo = [...prevArticulo];

            // Modificar el primer elemento del array
            if (nuevoArticulo[0]) {
                switch (name) {
                    case "codBar":
                        if (codBarEdit) {
                            nuevoArticulo[0].codBarras = value; // Actualiza la propiedad codBarras
                            nuevoArticulo[1].codBarras = value;
                            //console.log(value);

                        } else {
                            //no se modifica
                            setErrorEdit(true)

                            if (timer) {
                                clearTimeout(timer);
                            }
                            // Configurar un nuevo temporizador para ejecutar la función cambiarHoras
                            const newTimer = setTimeout(() => {
                                setErrorEdit(false)
                            }, 3000);
                            // Actualizar el estado del temporizador
                            setTimer(newTimer);
                        }
                        break;
                    case "codigoGw":
                        nuevoArticulo[0].codigoGw = value; // Actualiza la propiedad referencia
                        break;
                    case "nombre":
                        nuevoArticulo[0].nombre = value; // Actualiza la propiedad nombre
                        break;
                    case "precio":
                        nuevoArticulo[0].precio = value; // Actualiza la propiedad precio
                        break;
                    case "precioCompra":
                        nuevoArticulo[0].precioCompra = value; // Actualiza la propiedad precio
                        break;
                    case "cantidad":
                        setNuevoStock(prevNuevoStock => {
                            // Hacer una copia del estado previo
                            const nuevoValor = [...prevNuevoStock];
                            // Actualizar el índice correspondiente
                            nuevoValor[index] = value;
                            return nuevoValor; // Retorna el nuevo array actualizado
                        });
                        break;
                    default:
                        break;
                }
            }

            // Retorna el array actualizado
            return nuevoArticulo;
        });
    };


    const buscarArticulo = async (evento, value) => {
        if (value === '') {
            //console.log("Error");

        } else {
            try {
                const response = await getArticulo(evento, value);
                //console.log("aa", response.data.error)
                if (response.success && response.evento != "Nombre") {
                    setCargar(true)
                    // Cancelar el temporizador previo si existe
                    if (timer) {
                        clearTimeout(timer);
                    }
                    // Configurar un nuevo temporizador para ejecutar la función cambiarHoras
                    const newTimer = setTimeout(() => {
                        setCargar(false)
                        // setCargar(false)
                    }, 500);
                    // Actualizar el estado del temporizador
                    setTimer(newTimer);
                    setArticulo(response.data)

                    // if (response.data[0].codBarras === null || response.data[0].codBarras === '' || response.data[0].codBarras === "") {
                    //     setCodBarEdit(false);
                    // } else {
                    //     setCodBarEdit(true);
                    // }
                } else if (response.evento === "Nombre") {
                    setCargar(true)
                    // Cancelar el temporizador previo si existe
                    if (timer) {
                        clearTimeout(timer);
                    }
                    // Configurar un nuevo temporizador para ejecutar la función cambiarHoras
                    const newTimer = setTimeout(() => {
                        setCargar(false)
                        // setCargar(false)
                    }, 500);
                    // Actualizar el estado del temporizador
                    setTimer(newTimer);
                    setArrayArticulos(response.data)
                    setSelectArticulos(true)
                } else {
                    //--------------------------------NADA
                }
            } catch (error) {
                console.error("Error al añadir el cliente:", error);
                setError(true);
            }
        }

    }

    const cargarOpcionesForm = async () => {
        try {
            const response = await getOpcionesFormStock();
            if (response.success) {
                setOpciones(response.data)
                //console.log(response.data);

            }
        } catch (error) {
            console.error("Error al cargar opciones romulario:", error);
            setError(true);
        }
    }

    const editarArticulo = async (articulo, nuevoStock) => {

        //console.log(articulo);

        try {
            const response = await updateStock(articulo, nuevoStock);
            //console.log("aa", response)
            if (response.success) {
                setArticulo([
                    {
                        nombre: '',
                        codBarras: '',
                        codigoGw: '',
                        precio: null,
                        precioCompra: null,
                        almacen: null,
                        pasillo: null,
                        balda: null,
                        stock: 0,
                        cantidad: 0
                    },
                    {
                        nombre: '',
                        codBarras: '',
                        codigoGw: '',
                        precio: null,
                        precioCompra: null,
                        almacen: null,
                        pasillo: null,
                        balda: null,
                        stock: 0,
                        cantidad: 0
                    },
                ])
                setNuevoStock([0, 0])
                setEditado(true)

                if (timer) {
                    clearTimeout(timer);
                }

                // Establecer un nuevo temporizador para ejecutar la función después de 2000ms (2 segundos)
                setTimer(setTimeout(() => {
                    setEditado(false);

                }, 5000));
            }

        } catch (error) {
            console.error("Error al añadir el cliente:", error);
        }

    }

    const cambiarStock = (articulos, nuevoStock) => {
        //console.log(articulos);
        // Asegúrate de trabajar con una copia si es necesario
        const articulosActualizados = articulos.map((art, index) => {


            // Verifica que `stock` y `cantidad` sean números
            const stock = Number(art.stock) || 0;
            const cantidad = Number(nuevoStock[index]) || 0;

            // Actualiza el stock
            return {
                ...art,
                stock: stock + cantidad, // Suma o resta según el signo de `cantidad`
            };
        });

        // Llama a la función para editar el artículo con los cambios
        editarArticulo(articulosActualizados, nuevoStock);
    };


    const handleClose = () => {
        setError(false);
    };


    useEffect(() => {
        //console.log(param.codBarras);
        cargarOpcionesForm();
        if (param.codBarras != null) {
            setArticulo([
                {
                    nombre: '',
                    codBarras: param.codBarras,
                    codigoGw: '',
                    precio: null,
                    precioCompra: null,
                    almacen: null,
                    pasillo: null,
                    balda: null,
                    stock: 0,
                    cantidad: 0
                },
                {
                    nombre: '',
                    codBarras: param.codBarras,
                    codigoGw: '',
                    precio: null,
                    precioCompra: null,
                    almacen: null,
                    pasillo: null,
                    balda: null,
                    stock: 0,
                    cantidad: 0
                },
            ]);
            buscarArticulo("codBar", param.codBarras)
        }
    }, [])

    const iconMapping = {
        PercioVentaPrecioCompra: <PercioVentaPrecioCompra articulo={articulo} handledOnChange={handledOnChange}></PercioVentaPrecioCompra>
    };

    return (
        <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="flex-start"
            xs={12}
            className='load'
            gap={2}
            sx={{ pb: 10 }}
        >
            <Header></Header>

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                xs={11} sm={11} md={10} xl={8}
            >
                <SubHeader></SubHeader>
            </Grid>

            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                xs={11} sm={11} md={10} xl={8}
                className='borde-redondo sombra-corta'
                padding={2}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    md={12}
                >
                    <Grid item xs={12} md={3} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <AutoFixHighIcon sx={{ fontSize: 50, m: 1 }} color='naranja' />
                        <Typography variant="h4">
                            Editar Stock
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <Typography variant="h4">
                            Stock total: {articulo[0].stock + articulo[1].stock}
                        </Typography>
                    </Grid>


                    <div className="separador"></div>
                </Grid>


                <Grid
                    container
                    direction="row"
                    justifyContent="space-around"
                    alignItems="start"
                    xs={12}
                >
                    <Grid
                        container
                        direction="column"
                        justifyContent="start"
                        alignItems="start"
                        xs={12} md={5}
                    >
                        <Typography variant="h5" style={{ textAlign: 'left' }}>
                            Escanear Codigo de barras
                        </Typography>

                        <Button color='azul' variant="outlined" size="large" fullWidth onClick={() => camara ? setCamara(false) : setCamara(true)} style={{ padding: '0.95em', marginTop: '0.5em' }}>
                            <CameraAltIcon color='azul' />&nbsp;{camara ? 'Cerrar camara' : 'Abrir camara'}
                        </Button>

                        <Camara
                            camara={camara}
                            setCamara={setCamara}
                            barcode={barcode}
                            setBarcode={setBarcode}
                            buscarArticulo={buscarArticulo}
                            articulo={articulo[0]}
                            setArticulo={setArticulo}
                        ></Camara>

                        <div className="separador" style={{ margin: '0.95em 0em' }}></div>

                        <Typography variant="h5" style={{ textAlign: 'left' }}>
                            Codigo de Barras:
                        </Typography>

                        <FormControl sx={{ minWidth: '100%', marginTop: '0.5em' }} variant="outlined" color='azul'>
                            <InputLabel htmlFor="outlined-adornment-password">Codigo de Barras</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password"
                                color='azul'
                                name='codBar'
                                type="text"
                                // autoFocus
                                value={articulo[0].codBarras}
                                onChange={handledOnChange}
                                endAdornment={
                                    <InputAdornment position="end" onClick={() => buscarArticulo("codBar", articulo[0].codBarras)} >
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            edge="end"
                                        >
                                            <SearchIcon />
                                            <Typography variant="button" className='texto-gris' style={{ textAlign: 'left' }}>
                                                Buscar
                                            </Typography>
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Codigo de Barras"
                            />
                        </FormControl>

                        <div className="separador" style={{ margin: '0.95em 0em' }}></div>

                        <Typography variant="h5" style={{ textAlign: 'left' }}>
                            Codigo de Gestwin:
                        </Typography>

                        <FormControl sx={{ minWidth: '100%', marginTop: '0.5em' }} variant="outlined" color='azul'>
                            <InputLabel htmlFor="outlined-adornment-password"> Codigo de Gestwin</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password"
                                color='azul'
                                name='codigoGw'
                                type="text"
                                value={articulo[0].codigoGw}
                                onChange={handledOnChange}
                                endAdornment={
                                    <InputAdornment position="end" onClick={() => buscarArticulo("codigoGw", articulo[0].codigoGw)}>
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            edge="end"
                                        >
                                            <SearchIcon />
                                            <Typography variant="button" className='texto-gris' style={{ textAlign: 'left' }}>
                                                Buscar
                                            </Typography>
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Codigo de referencia"
                            />
                        </FormControl>

                        <div className="separador" style={{ margin: '0.95em 0em' }}></div>

                        <Typography variant="h5" style={{ textAlign: 'left' }}>
                            Articulo:&nbsp;
                            {articulo[0].nombre}
                        </Typography>

                        <FormControl sx={{ minWidth: '100%', marginTop: '0.5em' }} variant="outlined" color='azul'>
                            <InputLabel htmlFor="outlined-adornment-password">Nombre articulo</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password"
                                color='azul'
                                name='nombre'
                                type="text"
                                value={articulo[0].nombre}
                                onChange={handledOnChange}
                                endAdornment={
                                    <InputAdornment position="end" onClick={() => buscarArticulo("nombre", articulo[0].nombre)}>
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            edge="end"
                                        >
                                            <SearchIcon />
                                            <Typography variant="button" className='texto-gris' style={{ textAlign: 'left' }}>
                                                Buscar
                                            </Typography>
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Codigo de ariculo"
                            />
                        </FormControl>
                    </Grid>

                    <Grid
                        container
                        direction="column"
                        justifyContent="start"
                        alignItems="start"
                        xs={12} md={5}
                    >
                        {opciones.length >= 1 && (
                            <>
                                {opciones.map((opcion, index) => (
                                    <React.Fragment key={index}>
                                        {iconMapping[opcion.icon] || null}
                                    </React.Fragment>
                                ))}
                            </>
                        )}

                        {/* <Typography variant="h5" >
                            Precio Compra (€)
                        </Typography>


                        <TextField
                            fullWidth
                            id="outlined-basic"
                            // label="Precio €"
                            variant="outlined"
                            color='azul'
                            type='number'
                            name='precioCompra'
                            value={articulo[0].precioCompra}
                            onChange={handledOnChange}
                            inputProps={{ step: 0.01 }}
                            sx={{ marginTop: '0.5em' }}
                        />

                        <Typography variant="h5" >
                            Precio Venta (€)
                        </Typography>


                        <TextField
                            fullWidth
                            id="outlined-basic"
                            // label="Precio €"
                            variant="outlined"
                            color='azul'
                            type='number'
                            name='precio'
                            value={articulo[0].precio}
                            onChange={handledOnChange}
                            inputProps={{ step: 0.01 }}
                            sx={{ marginTop: '0.5em' }}
                        /> */}
                        {/* 
                        <Typography variant="h5" >
                            Pasillo:
                        </Typography>

                        <TextField
                            fullWidth
                            id="outlined-basic"
                            // label="Pasillo"
                            variant="outlined"
                            color='azul'
                            type='number'
                            name='precio'
                            value={articulo[0].pasillo}
                            onChange={handledOnChange}
                            sx={{ marginTop: '0.5em' }}

                        />

                        <div className="separador" style={{ margin: '0.95em 0em' }}></div>

                        <Typography variant="h5">
                            Balda:
                        </Typography>

                        <TextField
                            fullWidth
                            id="outlined-basic"
                            // label="Balda"
                            variant="outlined"
                            color='azul'
                            type='number'
                            name='precio'
                            value={articulo[0].balda}
                            onChange={handledOnChange}
                            sx={{ marginTop: '0.5em' }}
                        />*/}



                        <div className="separador" style={{ margin: '0.95em 0em' }}></div>

                        {articulo.map((info, index) => (
                            <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                xs={12}
                                gap={1}
                            >
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="start"
                                    xs={5}
                                >
                                    <Typography variant="h5" style={{ textAlign: 'left' }}>
                                        Cantidad:
                                    </Typography>

                                    <Grid item xs={12} md={12}>
                                        <TextField
                                            fullWidth
                                            id="outlined-basic"
                                            // label="Cantidad"
                                            variant="outlined"
                                            color='azul'
                                            type='number'
                                            name='cantidad'
                                            value={nuevoStock[index]}
                                            onChange={(event) => handledOnChange(event, index)}
                                            sx={{ marginTop: '0.5em' }}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="start"
                                    xs={5}
                                >
                                    <Typography variant="h5">
                                        Almacen {index + 1}:
                                    </Typography>
                                    <Grid item xs={12} md={12}>
                                        <TextField
                                            fullWidth
                                            id="outlined-basic"
                                            label={info.stock}
                                            variant="outlined"
                                            color='azul'
                                            type='number'
                                            onChange={handledOnChange}
                                            disabled
                                            sx={{ marginTop: '0.5em' }}
                                        />
                                    </Grid>
                                </Grid>



                                <Grid item xs={12} md={12} style={{ textAlign: 'left', marginTop: '0em' }}>
                                    <Typography variant="caption" className='texto-gris'>
                                        Escribe la cantidad añadida o substraída. (Ej: +5, -10...)
                                    </Typography>
                                </Grid>

                                <div className="separador" style={{ margin: '0.95em 0em' }}></div>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>

                <div className="separador" style={{ margin: '0.95em 0em' }}></div>


                <Grid
                    container
                    direction="row"
                    justifyContent="space-around"
                    alignItems="center"
                    xs={12}
                >
                    <Grid item xs={12} md={5}>
                        <Button fullWidth color='azul' variant="outlined" size="large" className='pointer' onClick={() => Navigate("/")} sx={{ pb: 2, pt: 2 }}>
                            Cancelar
                        </Button>
                    </Grid>

                    <Grid item xs={12} md={5}>
                        <Button fullWidth color='azul' variant="contained" size="large" className='pointer' onClick={() => cambiarStock(articulo, nuevoStock)} sx={{ pb: 2, pt: 2 }}>
                            Guardar Cambios
                        </Button>
                    </Grid>




                    {/* <div className="separador-2"></div> */}
                </Grid>


            </Grid>

            {/* ---------------------------------------------------------NOTIFICACIONES--------------------------------------------------------------- */}

            <Backdrop
                sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
                open={cargar}
            // onClick={handleClose}
            >
                <CircularProgress color='azul' />
            </Backdrop>

            <Snackbar
                open={editado}
                // autoHideDuration={6000}
                onClose={handleClose}
                message="Stock modificado con exito."
            // action={action}
            >
                <Alert variant="filled" severity="success" color='azul'>
                    Stock modificado con exito.
                </Alert>
            </Snackbar>


            <Snackbar
                open={errorEdit}
                // autoHideDuration={6000}
                onClose={handleClose}
                message="Stock modificado con exito."
            // action={action}
            >
                <Alert variant="filled" severity="error" color='error'>
                    El codigo de barras debe editarse desde Gestwin.
                </Alert>
            </Snackbar>

            <Dialog
                open={error}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Este articulo no existe"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Desea crear este articulo en la base de datos?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={handleClose}>Cancelar</Button>
                    <Button variant="contained" onClick={() => Navigate("/anadir-articulo/" + articulo[0].codBarras)} autoFocus>
                        Crear articulo
                    </Button>
                </DialogActions>
            </Dialog>

            <SelectorArticulos
                arrayArticulos={arrayArticulos}
                setSelectArticulos={setSelectArticulos}
                selectArticulos={selectArticulos}
                setArticulo={setArticulo}
                codBarEdit={codBarEdit}
                setCodBarEdit={setCodBarEdit}
            ></SelectorArticulos>


            {/* <CerrarSesion></CerrarSesion> */}
        </Grid>
    )
}
