import { Card, CardContent, Grid, IconButton, Typography } from '@mui/material'
import React from 'react'
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import TaskIcon from '@mui/icons-material/Task';
import FeedIcon from '@mui/icons-material/Feed'
import { useNavigate, useParams } from 'react-router-dom';
import ConstructionIcon from '@mui/icons-material/Construction';
import PersonIcon from '@mui/icons-material/Person';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';

export const OpcionesMenuNav = ({ opciones }) => {
    const iconMapping = {
        HomeRepairServiceIcon: <HomeRepairServiceIcon sx={{ fontSize: 50 }} />,
        TaskIcon: <TaskIcon sx={{ fontSize: 50 }} />,
        FeedIcon: <FeedIcon sx={{ fontSize: 50 }} />,
        ConstructionIcon: <ConstructionIcon sx={{ fontSize: 50 }} />,
        PersonIcon: <PersonIcon sx={{ fontSize: 50 }} />,
        AccountTreeIcon: <AccountTreeIcon sx={{ fontSize: 50 }} />,
        CircleNotificationsIcon: <CircleNotificationsIcon sx={{ fontSize: 50 }} />,
        AccessAlarmIcon: <AccessAlarmIcon sx={{ fontSize: 50 }} />

    };
    const navigate = useNavigate();
    const param = useParams();
    return (

        <Grid container xs={12} gap={1} justifyContent="space-around">
            {opciones.map(opcion => (

                <Grid item xs={12} sm={6} md={4} xl={3.5} key={opcion.id} className='borde-redondo sombra-corta pointer card-articulo' onClick={() => navigate(opcion.url)} padding={3}>

                    <Typography variant="h5" component="div">
                        <IconButton color='error'>
                            {iconMapping[opcion.icon]}
                        </IconButton>
                        {opcion.nombre}
                    </Typography>
                </Grid>

            ))}
        </Grid>
    )
}
