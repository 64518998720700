import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';



import WarehouseIcon from '@mui/icons-material/Warehouse';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import GroupIcon from '@mui/icons-material/Group';


import logo from '../media/logo-inelvan2.png';
import { getCookie } from '../utils/cookies';
import { getProyectosRecientes, getTrabajosRecientes } from '../services/apis';
import { CrearProyecto } from '../components/CrearProyecto';
import { ListaProyectos } from '../components/ListaProyectos';
import { CerrarSesion } from '../components/Botones/CerrarSesion';
import { Fichaje } from '../components/fichaje/Fichaje';
import { MainMenu } from '../components/MainMenu';




export const Inicio = () => {
    const Navigate = useNavigate();

    let tamIco = 70;
    const token = getCookie('PHPSESSID');
    const [proyectos, setProyectos] = useState([]);
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const getProyectos = async () => {
        try {
            const response = await getProyectosRecientes();
            //console.log("aa", response)
            setProyectos(response.data)
        } catch (error) {
            console.error("Error al añadir el cliente:", error);
        }
    }



    useEffect(() => {
        getProyectos();
    }, [])

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            xs={12}
            className='load fondo-blanco'
            sx={{ pb: 10 }}
        >
            {/* <Header></Header> */}
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                xs={11} md={8}
                // gap={4}
                className='margen-top'
            >
                <Grid item xs={8} sm={4} md={3.5} xl={2.5}
                    // onClick={() => Navigate("/")} 
                    className='no-select'
                >
                    <img src={logo} alt="" style={{ maxWidth: '100%' }} />
                </Grid>

                <Grid container xs={11}>
                    <div className="separador"></div>
                </Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    xs={12}
                    gap={2}
                    style={{ marginBottom: '2em', marginTop: '1em' }}
                >
                    <Fichaje></Fichaje>
                    <MainMenu handleClickOpen={handleClickOpen}></MainMenu>
                </Grid>
                
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    xs={11}
                    style={{ marginBottom: '1em' }}
                >
                    <Typography variant="h5" style={{ textAlign: 'center' }}>
                        Proyectos recientes
                    </Typography>
                    <div className="separador"></div>
                </Grid>
            </Grid>

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                xs={11} md={11}
                style={{ overflow: 'auto', maxHeight: '40vh' }}
                padding={2}
            >
                <ListaProyectos proyectos={proyectos}></ListaProyectos>
            </Grid>

            <CerrarSesion></CerrarSesion>

            <CrearProyecto open={open} setOpen={setOpen}></CrearProyecto>

        </Grid>

    )
}
