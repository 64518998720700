import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Collapse from '@mui/material/Collapse';

import SearchIcon from '@mui/icons-material/Search';


export const Filtros = ({ filtros, setFiltros, articulos, setArticulos, articulosOriginal }) => {

    const [codBarras, setCodBarras] = useState("");
    const [codigoGw, setCodigoGw] = useState("");
    const [nombre, setNombre] = useState("");


    const handleOnChange = (event, articulosOriginal) => {
        //console.log(event.target);

        const valor = event.target.value; // Valor del input
        const nombreCampo = event.target.name; // Campo que queremos filtrar
        let resultadoFiltrado = [];
        let articulosOg = articulosOriginal;



        switch (nombreCampo) {
            case "codBarras":
                resultadoFiltrado = articulosOriginal.filter(articulo =>
                    articulo[1].codBarras && articulo[1].codBarras.toUpperCase().includes(valor.toUpperCase())
                );
                setCodBarras(valor);
                break;

            case "codigoGw":
                resultadoFiltrado = articulosOriginal.filter(articulo =>
                    articulo[1].codigoGw && articulo[1].codigoGw.toUpperCase().includes(valor.toUpperCase())
                );
                setCodigoGw(valor);
                break;

            case "nombre":
                resultadoFiltrado = articulosOriginal.filter(articulo =>
                    articulo[1].nombre && articulo[1].nombre.toUpperCase().includes(valor.toUpperCase())
                );
                setNombre(valor);
                break;

            default:
                resultadoFiltrado = articulosOriginal; // Sin filtro en caso de que no coincida
                break;
        }

        if (resultadoFiltrado.lenght = 0) {
            vaciarFiltros(articulosOg);
        } else {
            setArticulos(resultadoFiltrado); // Actualiza el estado con los resultados filtrados
        }
    };

    const vaciarFiltros = (articulosOriginal) => {
        //console.log(articulosOriginal);
        setArticulos(articulosOriginal)
        setCodBarras("")
        setCodigoGw("")
        setNombre("")
    }


    return (
        <Collapse in={filtros} style={{ minWidth: '100%' }}>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                md={12}
                padding={2}
                style={{ overflow: 'hidden' }}
            >
                <Grid
                    container
                    direction="column"
                    justifyContent="start"
                    alignItems="start"
                    xs={12} sm={5} md={3.7} xl={2.9}
                    padding={1.5}
                >
                    <Typography variant="h6">
                        Buscar nombre
                    </Typography>
                    <FormControl sx={{ minWidth: '100%' }} variant="outlined" color='azul'>
                        <InputLabel htmlFor="outlined-adornment-password">Nombre</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password"
                            color='azul'
                            name='nombre'
                            type="text"
                            onChange={(event) => handleOnChange(event, articulosOriginal)}
                            value={nombre}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        edge="end"
                                    >
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Nombre"
                        />
                    </FormControl>
                </Grid>

                <Grid
                    container
                    direction="column"
                    justifyContent="start"
                    alignItems="start"
                    xs={12} sm={5} md={3.7} xl={2.9}
                    padding={1.5}

                >
                    <Typography variant="h6">
                        Buscar codigo de barras
                    </Typography>
                    <FormControl sx={{ minWidth: '100%' }} variant="outlined" color='azul'>
                        <InputLabel htmlFor="outlined-adornment-password">Codigo de barras</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password"
                            color='azul'
                            name='codBarras'
                            type="text"
                            onChange={(event) => handleOnChange(event, articulosOriginal)}
                            value={codBarras}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        edge="end"
                                    >
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Codigo de barras"
                        />
                    </FormControl>
                </Grid>

                <Grid
                    container
                    direction="column"
                    justifyContent="start"
                    alignItems="start"
                    xs={12} sm={5} md={3.7} xl={2.9}
                    padding={1.5}
                >
                    <Typography variant="h6">
                        Buscar codigo de Gestwin
                    </Typography>
                    <FormControl sx={{ minWidth: '100%' }} variant="outlined" color='azul'>
                        <InputLabel htmlFor="outlined-adornment-password">Codigo de Gestwin</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password"
                            color='azul'
                            name='codigoGw'
                            type="text"
                            onChange={(event) => handleOnChange(event, articulosOriginal)}
                            value={codigoGw}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        edge="end"
                                    >
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Codigo de Gestwin"
                        />
                    </FormControl>
                </Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="end"
                    xs={12} sm={5} md={3.7} xl={2.9}
                    padding={1.5}
                    sx={{
                        height: {
                            xl: '5vh'  // Altura para pantallas extra grandes
                        }
                    }}
                >
                    <Button fullWidth variant='contained' color='azul' onClick={() => vaciarFiltros(articulosOriginal)} sx={{ pt: 1.8, pb: 1.9 }}>vaciar filtros</Button>
                </Grid>


            </Grid>
        </Collapse>
    )
}
