import React, { useEffect, useRef, useState } from 'react'

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';

import SquareIcon from '@mui/icons-material/Square';
import { Checkbox } from '@mui/material';


export const FiltrosTrabajos = ({ trabajos, setTrabajosFiltrados, estados, setEstados }) => {


    // const [trabajosFiltrados, settrabajosFiltrados] = useState([]);
    //console.log(trabajos);
    const [searchTerm, setSearchTerm] = useState('');
    // Obtener la fecha actual y la fecha de hace 2 meses
    const today = new Date();
    const twoMonthsAgo = new Date();
    twoMonthsAgo.setMonth(today.getMonth() - 6);
    // Formatear las fechas en formato 'YYYY-MM-DD' (ISO)
    const formatDate = (date) => {
        return date.toISOString().split('T')[0];
    };

    const [filtros, setFiltros] = useState(
        {
            fechaDesde: formatDate(twoMonthsAgo),  // Fecha de hace 2 meses
            fechaHasta: formatDate(today),         // Fecha actual

        }
    );
    const filtrosRef = useRef(filtros);




    //filtros Estados
    const filtrartrabajos = (updatedEstados, trabajos) => {

        // Filtrar por estados >>>>
        // Obtén los IDs de los estados marcados
        const checkedEstadoIds = updatedEstados
            .filter(estado => estado.checked)
            .map(estado => estado.id);

        // Filtra las trabajos según los estados marcados
        const filteredtrabajos = trabajos.filter(tarea =>
            checkedEstadoIds.includes(parseInt(tarea.id_estado))
        );

        // let trabajosFiltradasSearchTerm = filteredtrabajos;

        //filtrar por texto
        // if (searchTerm.length > 1) {
        //     const propiedadesParaBuscar = ['asunto', 'descripcion', 'fecha', 'cliente', 'correo', 'trabajadores'];

        //     trabajosFiltradasSearchTerm = filteredtrabajos.filter(tarea =>
        //         propiedadesParaBuscar.some(propiedad =>
        //             tarea[propiedad] &&
        //             typeof tarea[propiedad] === 'string' &&
        //             tarea[propiedad].toLowerCase().includes(searchTerm.toLowerCase())
        //         )
        //     );

        // }


        // document.title = `(${trabajosFiltradasSearchTerm.length}) Tessa: trabajos`; // Actualiza el título del documento

        // return trabajosFiltradasSearchTerm; // Devuelve las trabajos filtradas
        return filteredtrabajos; // Devuelve las trabajos filtradas

    };

    const handleCheckboxChange = (id) => {
        //console.log(id);

        const updatedEstados = estados.map((estado) =>
            estado.id === id ? { ...estado, checked: !estado.checked } : estado
        );

        setEstados(updatedEstados);
        setTrabajosFiltrados(filtrartrabajos(updatedEstados, trabajos));

    };

    useEffect(() => {
        const tempFIl = filtrartrabajos(estados, trabajos);
        //console.log("estados", estados)
        setTrabajosFiltrados(tempFIl);
    }, [trabajos]);



    useEffect(() => {
        filtrosRef.current = filtros;
        // cargarTareas();
    }, [filtros]);

    // useEffect(() => {
    //     paramRef.current = param;
    // }, [param]);

    useEffect(() => {
        setTrabajosFiltrados(filtrartrabajos(estados, trabajos));
    }, [searchTerm]);

    useEffect(() => {
        //console.log("t", estados, "t", trabajos);
    }, [])



    return (
        <>
            {estados.length >= 1 && (
                <Grid
                    container
                    direction="row"
                    justifyContent="space-around"
                    alignItems="center"
                    xs={12}
                    className='no-select'
                >
                    {estados.map(estado => (
                        // <Grid container xs={6} sm={4} md={3} xl={2} direction="row" justifyContent="center" alignItems="center" fontWeight={700} key={estado.id}>
                        //     {estado.nombre}&nbsp;
                        //     <SquareIcon style={{
                        //         color: "#" + estado.color,
                        //         backgroundColor: "#" + estado.color,
                        //         borderRadius: "4px",
                        //         boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px'
                        //     }} />
                        //     <Checkbox
                        //         checked={estado.checked == 1 ? true : false}
                        //         onChange={() => handleCheckboxChange(estado.id)}  // Cambia el estado al hacer clic
                        //         inputProps={{ 'aria-label': 'controlled' }}
                        //     />
                        // </Grid>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            xs={6} md={2}

                        >
                            <Switch color={estado.color} checked={estado.checked == 1 ? true : false} onChange={() => handleCheckboxChange(estado.id)} />
                            <Typography variant="subtitle2">
                                {estado.nombre}&nbsp;
                            </Typography>
                        </Grid>

                    ))}
                </Grid >
            )}
        </>
    )
}
