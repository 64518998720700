import React, { useEffect, useState } from 'react';
import { Button, Card, CardContent, CircularProgress, Grid, IconButton, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import TodayIcon from '@mui/icons-material/Today';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';
import Groups2Icon from '@mui/icons-material/Groups2';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import axios from 'axios';
import { getCookie } from '../utils/cookies';
import { getopcinesMenu } from '../services/apis'

import QuickreplyIcon from '@mui/icons-material/Quickreply';
import ConstructionIcon from '@mui/icons-material/Construction';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import GroupIcon from '@mui/icons-material/Group';
//mapa de iconos
let tamIco = 70;
const iconMapping = {
    TodayIcon: <TodayIcon />,
    FolderSpecialIcon: <FolderSpecialIcon />,
    Groups2Icon: <Groups2Icon />,
    AdminPanelSettingsIcon: <AdminPanelSettingsIcon sx={{ fontSize: 70 }} color='error' />,
    QuickreplyIcon: <QuickreplyIcon />,
    ConstructionIcon: <ConstructionIcon />,
    AccessAlarmIcon: <AccessAlarmIcon sx={{ fontSize: 70 }} color='gris' />,
    GroupAddIcon: <GroupAddIcon sx={{ fontSize: tamIco }} color='azul' />,
    GroupIcon: <GroupIcon sx={{ fontSize: tamIco }} color='negro' />,
    AutoFixHighIcon: <AutoFixHighIcon sx={{ fontSize: tamIco }} color='naranja' />,
    LibraryAddIcon: <LibraryAddIcon sx={{ fontSize: tamIco }} color='verde' />,
    WarehouseIcon: <WarehouseIcon sx={{ fontSize: tamIco }} color='action' />
};

export const MainMenu = ({handleClickOpen}) => {
    // Global
    const navigate = useNavigate();

    // Estados auxiliares
    const [loaded, setLoaded] = useState(false);

    // Estados útiles
    const [opciones, setOpciones] = useState([]);

    // Cargar opciones menú principal
    const cargarOpciones = async () => {
        try {
            const response = await getopcinesMenu();
            //console.log("aa", response.data)
            setOpciones(response.data)
            setLoaded(true);
        } catch (error) {
            console.error("Error al añadir el cliente:", error);
        }
    };

    const handleEvent = (opcionId) => {
        //console.log(opcionId);
        switch (opcionId) {
            case "1":
                handleClickOpen();
                break;
            default:
                //console.log("Lógica para una opción desconocida");
                // Lógica para una opción desconocida
                break;
        }
    }

    useEffect(() => {
        cargarOpciones();
    }, []);

    return (
        <>
            {!loaded && (<CircularProgress />)}
            {loaded && (
                <>
                    {/* <Grid xs={12} container spacing={3} justifyContent="center"> */}
                    {/* <Fichar></Fichar> */}
                    {opciones.map((opcion) => (<>
                        {/* <Grid item xs={12} key={opcion.id}> */}

                        {opcion.tipo == 1 && (
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                xs={11} md={5}
                                className='borde-redondo sombra-corta pointer card-articulo'
                                onClick={() => handleEvent(opcion.onClick)}
                                padding={2}
                                gap={3}
                            >
                                <Grid container xs={2} style={{ marginRight: 10 }}>
                                    {iconMapping[opcion.icon]}
                                </Grid>
                                <Grid container xs={12} md={8}
                                    direction="column"
                                    justifyContent="start"
                                    alignItems="flex-start"
                                >
                                    <Typography variant="h6" gutterBottom>
                                        {opcion.nombre}
                                    </Typography>
                                    <Typography variant="subtitle1" gutterBottom className='texto-gris'>
                                        {opcion.descripcion}
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}
                        {
                            opcion.tipo == 0 && (
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    xs={11} md={5}
                                    className='borde-redondo sombra-corta pointer card-articulo'
                                    onClick={opcion.onClick === null ? () => navigate(opcion.url) : () => opcion.onClick} // Corregido aquí
                                    padding={2}
                                    gap={3}
                                >
                                    <Grid container xs={2} style={{ marginRight: 10 }}>
                                        {iconMapping[opcion.icon]}
                                    </Grid>
                                    <Grid container xs={12} md={8}
                                        direction="column"
                                        justifyContent="start"
                                        alignItems="flex-start"
                                    >
                                        <Typography variant="h6" gutterBottom>
                                            {opcion.nombre}
                                        </Typography>
                                        <Typography variant="subtitle1" gutterBottom className='texto-gris'>
                                            {opcion.descripcion}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}
                        {/* </Grid> */}
                    </>
                    ))}
                    {/* </Grid> */}
                </>
            )}
        </>
    );
};
