import axios from "axios";
import { getCookie } from "../utils/cookies";


const baseUrl = 'https://www.inelvan.com/stockapp/WS/'

// ----------------------------------------------------------TRABAJOS------------------------------------------
// --------------------------------------------------------------------------------------------------------------

// ----------------------------------------------------------GET------------------------------------------
export const getTrabajo = async () => {
    try {
        const response = await axios.get(baseUrl + 'trabajos.php?', {
            params: {
                token: getCookie('PHPSESSID'),
                // idCliente: idCliente
            },
        });

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;

        } else {
            //console.log('No se pudo verificar el token', response);
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};

export const getTrabajos = async (idProyecto) => {
    try {
        const response = await axios.get(baseUrl + 'trabajos.php?', {
            params: {
                token: getCookie('PHPSESSID'),
                idProyecto: idProyecto
            },
        });

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;

        } else {
            //console.log('No se pudo verificar el token', response);
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};

export const getTrabajosFecha = async (idProyecto, fechaInicio, fechaFin) => {
    try {
        const response = await axios.get(baseUrl + 'trabajos.php?', {
            params: {
                token: getCookie('PHPSESSID'),
                idProyecto: idProyecto,
                fechaInicio: fechaInicio,
                fechaFin: fechaFin
            },
        });

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;

        } else {
            //console.log('No se pudo verificar el token', response);
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};


export const getProyectos = async () => {
    try {
        const response = await axios.get(baseUrl + 'proyectos.php?', {
            params: {
                token: getCookie('PHPSESSID'),
                // idCliente: idCliente
            },
        });

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;

        } else {
            //console.log('No se pudo verificar el token', response);
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};

export const getProyectosFecha = async (fechaInicio, fechaFin) => {
    try {
        const response = await axios.get(baseUrl + 'proyectos.php?', {
            params: {
                token: getCookie('PHPSESSID'),
                fechaInicio: fechaInicio,
                fechaFin: fechaFin
            },
        });

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;

        } else {
            //console.log('No se pudo verificar el token', response);
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};



export const getTrabajosRecientes = async () => {
    try {
        const response = await axios.get(baseUrl + 'trabajos.php?', {
            params: {
                token: getCookie('PHPSESSID'),
                recientes: 1
            },
        });

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;

        } else {
            //console.log('No se pudo verificar el token', response);
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};

export const getProyectosRecientes = async () => {
    try {
        const response = await axios.get(baseUrl + 'proyectos.php?', {
            params: {
                token: getCookie('PHPSESSID'),
                recientes: 1
            },
        });

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;

        } else {
            //console.log('No se pudo verificar el token', response);
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};

export const getEditarTrabajo = async (idTrabajo) => {
    try {
        const response = await axios.get(baseUrl + 'trabajos.php?', {
            params: {
                token: getCookie('PHPSESSID'),
                idTrabajo: idTrabajo
            },
        });
        // //console.log(response);

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;

        } else {
            //console.log('No se pudo verificar el token', response);
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};


export const getArticulo = async (evento, value) => {
    // //console.log("AAAAAAAAAAAAA", evento, value);

    try {
        const response = await axios.get(baseUrl + 'stock.php?', {
            params: {
                token: getCookie('PHPSESSID'),
                evento: evento,
                value: value
            },
        });

        //console.log(response);


        if (response.data.success) {
            //console.log(response.data);

            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;

        } else {
            //console.log('No se pudo verificar el token', response);
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};

export const getArticulos = async () => {

    try {
        const response = await axios.get(baseUrl + 'stock.php?', {
            params: {
                token: getCookie('PHPSESSID'),
                todos: true
            },
        });

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;

        } else {
            //console.log('No se pudo verificar el token', response);
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};

export const getEmpleados = async () => {

    try {
        const response = await axios.get(baseUrl + 'empleados.php?', {
            params: {
                token: getCookie('PHPSESSID'),
                todos: true
            },
        });

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;

        } else {
            //console.log('No se pudo verificar el token', response);
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};

export const getEmpleadosAsig = async (idTrabajo) => {

    try {
        const response = await axios.get(baseUrl + 'empleados.php?', {
            params: {
                token: getCookie('PHPSESSID'),
                asignados: true,
                idTrabajo: idTrabajo
            },
        });
        // //console.log("APIS", response);

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;

        } else {
            //console.log('No se pudo verificar el token', response);
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};

export const getFichaje = async (fecha) => {
    try {
        const response = await axios.get(baseUrl + 'fichajes.php?', {
            params: {
                token: getCookie('PHPSESSID'),
                fecha: fecha
            },
        });
        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            //console.log(response.data)
            return response.data;
        } else {
            //console.log('Error');
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};

export const getTodosFichajes = async (mes, year) => {
    try {
        const response = await axios.get(baseUrl + 'allfichajes.php?', {
            params: {
                token: getCookie('PHPSESSID'),
                year: year,
                mes: mes
            },
        });
        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            //console.log(response.data)
            return response.data;
        } else {
            //console.log('Error');
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};

export const getFichajesUsuario = async (mes, year, id) => {
    try {
        const response = await axios.get(baseUrl + 'fichajeUsuario.php?', {
            params: {
                token: getCookie('PHPSESSID'),
                mes: mes,
                year: year,
                id: id
            },
        });
        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            //console.log(response.data)
            return response.data;
        } else {
            //console.log('Error');
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};

export const getopcinesMenu = async () => {
    try {
        const response = await axios.get(baseUrl + 'opcionesmenu.php', {
            params: { token: getCookie('PHPSESSID') },
        });

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;
        } else {
            //console.log('No se pudo verificar el token');
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};

//recursos humanos
export const getOpcionesRrhh = async () => {
    try {
        const response = await axios.get(baseUrl + 'opcionesrrhh.php', {
            params: { token: getCookie('PHPSESSID') },
        });

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;
        } else {
            //console.log('No se pudo verificar el token');
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};

//roles y permisos
export const getRolesYDepartamentos = async () => {
    try {
        const response = await axios.get(baseUrl + 'rolesydepartamentos.php', {
            params: { token: getCookie('PHPSESSID') },
        });

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;
        } else {
            //console.log('No se pudo verificar el token');
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
}

export const getUsuarios = async () => {
    try {
        const response = await axios.get(baseUrl + 'usuarios.php', {
            params: { token: getCookie('PHPSESSID') },
        });

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;
        } else {
            //console.log('No se pudo verificar el token');
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
}

export const getOpcionesAdmin = async () => {
    try {
        const response = await axios.get(baseUrl + 'opcionesAdministrador.php', {
            params: { token: getCookie('PHPSESSID') },
        });

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;
        } else {
            //console.log('No se pudo verificar el token');
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
}

export const getOpcionesFormStock = async () => {
    try {
        const response = await axios.get(baseUrl + 'getOpcionesFormStock.php', {
            params: { token: getCookie('PHPSESSID') },
        });

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;
        } else {
            //console.log('No se pudo verificar el token');
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
}

export const getEstados = async (id) => {
    try {
        const response = await axios.get(baseUrl + 'estados.php?', {
            params: {
                token: getCookie('PHPSESSID'),
                id: id
            },
        });

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;
        } else {
            //console.log('Error');
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};

export const getEstadosProyectos = async (id) => {
    try {
        const response = await axios.get(baseUrl + 'estadosProyectos.php?', {
            params: {
                token: getCookie('PHPSESSID'),
                id: id
            },
        });

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;
        } else {
            //console.log('Error');
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};


// ----------------------------------------------------------POST------------------------------------------
export const postTrabajos = async (trabajo) => {
    // //console.log("APIS", trabajo);

    try {
        const response = await axios.post(baseUrl + 'trabajos.php?', {
            token: getCookie('PHPSESSID'),
            data: trabajo
        });
        // //console.log("aaaa", response);
        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;
        } else {
            //console.log(response.data);
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};

export const postProyectos = async (nombre) => {
    try {
        const response = await axios.post(baseUrl + 'proyectos.php?', {
            token: getCookie('PHPSESSID'),
            nombre: nombre
        });
        // //console.log("aaaa", response);

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;
        } else {
            //console.log(response.data);
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};

export const postAriculo = async (articulo) => {
    // //console.log("ARTICULO",articulo)
    try {
        const response = await axios.post(baseUrl + 'stock.php?', {
            token: getCookie('PHPSESSID'),
            data: articulo,
        });
        //console.log("APIS", response);

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;
        } else {
            //console.log(response.error);
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};

export const asigEmpleados = async (empleados, idTrabajo) => {
    // //console.log("empleados", empleados, idTrabajo)
    try {
        const response = await axios.post(baseUrl + 'empleados.php?', {
            token: getCookie('PHPSESSID'),
            data: empleados,
            asignar: true,
            idTrabajo: idTrabajo
        });
        // //console.log("APIS", response);

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;
        } else {
            //console.log(response.error);
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};

export const postAriculoTrabajo = async (articulo) => {
    // //console.log("ARTICULO", articulo)
    try {
        const response = await axios.post(baseUrl + 'articulosTrabajos.php?', {
            token: getCookie('PHPSESSID'),
            data: articulo,
        });
        // //console.log("APIS", response);

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;
        } else {
            //console.log(response.error);
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};

//fichaje

export const inicioFichaje = async (data) => {
    try {
        const response = await axios.post(baseUrl + 'fichajes.php', {
            token: getCookie('PHPSESSID'),
            data

        });
        //console.log(response.data);
        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario

            return response.data;
        } else {
            //console.log(response.data);
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};

export const postUsuario = async (data) => {
    try {
        const response = await axios.post(baseUrl + 'usuario.php', {
            token: getCookie('PHPSESSID'),
            data: data

        });
        //console.log(response.data);
        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario

            return response.data;
        } else {
            //console.log(response.data);
            return response.data;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return error.data;
    }

}

// ----------------------------------------------------------PUT-------------------------------------------------------
export const finalizarTrabajo = async (idTrabajo, articulos, empleadoAsig) => {
    //console.log(idTrabajo, articulos, empleadoAsig)
    try {
        const response = await axios.put(baseUrl + 'finalizarTrabajo.php?', {
            token: getCookie('PHPSESSID'),
            data: articulos,
            idTrabajo: idTrabajo,
            empleadoAsig: empleadoAsig
        });

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;
        } else {
            //console.log(response.data);
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};

export const finalizarProyecto = async (estado, idProyecto) => {
    //console.log("AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA", estado, idProyecto);
    
    try {
        const response = await axios.put(baseUrl + 'finalizarProyecto.php?', {
            token: getCookie('PHPSESSID'),
            estado: estado,
            idProyecto: idProyecto,
        });

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;
        } else {
            //console.log(response.data);
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};

export const facturarTrabajo = async (estado, idTrabajo, idProyecto) => {
    //console.log("AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA", estado, idProyecto);
    
    try {
        const response = await axios.put(baseUrl + 'trabajos.php?', {
            token: getCookie('PHPSESSID'),
            estado: estado,
            idTrabajo: idTrabajo,
            idProyecto: idProyecto,
        });

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;
        } else {
            //console.log(response.data);
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};

export const updateStock = async (articulo, nuevoStock) => {
    try {
        //console.log("BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBB", articulo)

        const response = await axios.put(baseUrl + 'stock.php?', {
            token: getCookie('PHPSESSID'),
            data: articulo,
            nuevoStock: nuevoStock
        });
        // //console.log("aaaa", response.success);

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;
        } else {
            //console.log(response.data);
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error.response);
        return null;
    }
};

export const updateHoras = async (trabajador, idTrabajo) => {
    try {
        //console.log("BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBB", trabajador)

        const response = await axios.put(baseUrl + 'empleados.php?', {
            token: getCookie('PHPSESSID'),
            data: trabajador,
            idTrabajo: idTrabajo
        });
        //console.log("aaaa", response);

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;
        } else {
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error.response);
        return null;
    }
};

export const updateAriculoTrabajo = async (articulo) => {
    //console.log("ARTICULO", articulo)
    try {
        const response = await axios.put(baseUrl + 'articulosTrabajos.php?', {
            token: getCookie('PHPSESSID'),
            data: articulo,
        });
        //console.log("APIS", response);

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;
        } else {
            //console.log(response.error);
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};

export const finFichaje = async (data) => {
    try {
        const response = await axios.put(baseUrl + 'fichajes.php?', {
            token: getCookie('PHPSESSID'),
            data

        });
        //console.log("a", data)
        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            //console.log(response);

            return response.data;
        } else {
            //console.log(response);
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);

        return { error: 407 };
    }
};

export const actualizarRolesYDepartamentos = async (data, option, rolid) => {
    try {
        const response = await axios.put(baseUrl + 'rolesydepartamentos.php?', {
            token: getCookie('PHPSESSID'),
            option: option,
            rolid: rolid,
            data

        });
        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            //console.log(response);

            return response.data;
        } else {
            //console.log(response);
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};

export const actualizarUsuario = async (data) => {
    try {
        const response = await axios.put(baseUrl + 'usuario.php?', {
            token: getCookie('PHPSESSID'),
            data

        });
        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            //console.log(response);

            return response.data;
        } else {
            return response.data;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};

export const updateEstado = async (estado, idTrabajo, idProyecto) => {
    try {
        const response = await axios.put(baseUrl + 'trabajos.php?', {
            token: getCookie('PHPSESSID'),
            estado: estado,
            idTrabajo: idTrabajo,
            idProyecto: idProyecto
        });

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            //console.log(response);

            return response.data;
        } else {
            return response.data;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};

// ----------------------------------------------------------DELETE-------------------------------------------------------

export const desasignarEmp = async (data, idTrabajo) => {
    //console.log(data)
    try {
        const response = await axios.delete(baseUrl + 'deleteEmpleado.php?', {
            data: {
                data: data,
                token: getCookie('PHPSESSID'),
                // idTrabajo: idTrabajo
            }
        });
        //console.log("APIS", response);

        if (response.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;
        } else {
            //console.log(response.error);
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};

export const delUsuario = async (id) => {
    try {
        const response = await axios.delete(baseUrl + 'usuario.php', {
            data: {
                token: getCookie('PHPSESSID'),
                data: id
            }

        });
        //console.log(id, response.data);
        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario

            return response.data;
        } else {
            //console.log(response.data);
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }

}

export const deleteArticulo = async (codBarras, idTrabajo) => {
    //console.log(codBarras, idTrabajo);

    try {
        const response = await axios.delete(baseUrl + 'deleteArticulo.php?', {
            data: {
                token: getCookie('PHPSESSID'),
                codBarras: codBarras,
                idTrabajo: idTrabajo
            }

        });
        //console.log(response);

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return true;
        } else {
            //console.log(response.data);
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }

}

