import React, { useEffect, useState } from 'react';
import { CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Checkbox, Paper, Grid } from '@mui/material';
import { getRolesYDepartamentos } from '../../../services/apis';
import { PermisosOpciones } from './PermisosOpciones';
import { SubHeader } from '../../SubHeader';

export const RolesYDepartamentos = () => {
    const [opciones, setOpciones] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [roles, setRoles] = useState([]);

    const cargarOpciones = async () => {

        try {
            const response = await getRolesYDepartamentos();
            //console.log(response.data)
            setOpciones(response.data.opciones);
            setRoles(response.data.roles);
            setLoaded(true);
        } catch (error) {
            console.error('Error al cargar las opciones:', error);
        }
    };

    useEffect(() => {
        cargarOpciones();
    }, []);



    return (
        <Grid container justifyContent={'center'} xs={12}>
            <SubHeader />
            <h1>Roles y Departamentos</h1>

            {!loaded && <CircularProgress />}
            {loaded && (
                <>
                    {opciones.map(opcion => (
                        <>
                            <PermisosOpciones roles={roles} opciones={opcion.opciones} title={opcion.nombre} nombreArray={opcion.url} />
                        </>
                    ))}
                </>
            )}
        </Grid>
    );
};
