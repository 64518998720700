import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Pagination from '@mui/material/Pagination';


export const SelectorArticulos = ({ arrayArticulos, setSelectArticulos, selectArticulos, setArticulo, setCodBarEdit }) => {
    const itemsPerPage = 10; // Máximo de artículos por página

    const [currentPage, setCurrentPage] = useState(1); // Estado para la página actual

    // Manejador para cambiar de página
    const handlePageChange = (event, value) => {
        setCurrentPage(value);
        // handleScrollToTop();
    };

    const handleScrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth", // Desplazamiento suave
        });
    };

    const handleClose = () => {
        setSelectArticulos(false);
    };

    const seleccionarArt = (art) => {
        setArticulo(art);

        // if (art[0].codBarras === '' || art[0].codBarras === null) {
        //     setCodBarEdit(false)
        // } else {
        //     setCodBarEdit(true)
        // }
        setSelectArticulos(false);
    }



    // Cálculo para dividir el array en páginas
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentArticulos = arrayArticulos.slice(indexOfFirstItem, indexOfLastItem);

    return (
        <>
            <Dialog
                fullWidth
                maxWidth={'lg'}
                open={selectArticulos}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Typography variant="h4" >
                        Selecciona el articulo
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        xs={12}
                        gap={1}
                    >
                        {currentArticulos.map((art, index) => (
                            <>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="start"
                                    alignItems="start"
                                    xs={12}
                                    gap={1}
                                    // className='pointer card-articulo'
                                    padding={1}
                                >
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="start"
                                        alignItems="start"
                                        xs={12}
                                    >
                                        <Typography variant="h5">
                                            {art[0].nombre}
                                        </Typography>
                                        <Grid
                                            container
                                            direction="column"
                                            justifyContent="start"
                                            alignItems="start"
                                            xs={12}
                                        >
                                            <Typography variant="caption" >
                                                Stock almacen 1: {art[0].stock}
                                            </Typography>
                                            <Typography variant="caption" >
                                                Stock almacen 2: {art[1].stock}
                                            </Typography>
                                        </Grid>

                                    </Grid>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        xs={12}
                                    >
                                        <Typography variant="body1" >
                                            Cod. Gestwin: {art[0].codigoGw}
                                        </Typography>
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="end"
                                            alignItems="end"
                                            xs={12} md={2}
                                        >
                                            <Button fullWidth color="azul" variant='contained' onClick={() => seleccionarArt(art)}>SELECCIONAR</Button>

                                        </Grid>
                                    </Grid>


                                </Grid>

                                <div className="separador"></div>

                            </>

                        ))}
                    </Grid>




                </DialogContent>
                <DialogActions>

                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        xs={12}
                        padding={1}
                    >
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            xs={12}
                            sx={{ mt: 0 }}
                        >
                            <Pagination
                                count={Math.ceil(arrayArticulos.length / itemsPerPage)}
                                page={currentPage}
                                siblingCount={0}
                                onChange={handlePageChange}
                                color="azul"
                                shape="rounded"
                            />
                        </Grid>

                        <div className="separador"></div>

                        <Button fullWidth variant="outlined" onClick={handleClose} sx={{ pb: 2, pt: 2 }}>Cancelar</Button>
                        {/* <Button variant="contained" onClick={() => Navigate("/anadir-articulo/" + articulo[0].codBarras)} autoFocus>
                        Crear articulo
                    </Button> */}
                    </Grid>

                </DialogActions>
            </Dialog>

        </>
    )
}
