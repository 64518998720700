import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import ButtonGroup from '@mui/material/ButtonGroup';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import Pagination from '@mui/material/Pagination';


import GroupIcon from '@mui/icons-material/Group';
import LoupeIcon from '@mui/icons-material/Loupe';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import DescriptionIcon from '@mui/icons-material/Description';

import { Header } from '../components/Header';
import { ListaTrabajos } from '../components/ListaTrabajos';
import { getEstados, getTrabajos, getTrabajosFecha } from '../services/apis';
import { SubHeader } from '../components/SubHeader';
import { CerrarSesion } from '../components/Botones/CerrarSesion';
import { Estados } from '../components/Estados';
import { FiltrosTrabajos } from '../components/FiltrosTrabajos';
// import { DialogFinalizar } from '../../DialogFinalizar';
import { updateEstado } from '../services/apis';
import { DialogFinalizar } from '../components/DialogFinalizar';

export const Trabajos = () => {

    const param = useParams();
    const Navigate = useNavigate();

    const [trabajosFinal, setTrabajosFinal] = useState([]);
    const [trabajos, setTrabajos] = useState([]);
    const [mostrarFinal, setMostrarFinal] = useState(false)
    const [infoPy, setInfoPy] = useState([]);
    ////fitlros
    const [trabajosFiltrados, setTrabajosFiltrados] = useState([]);
    const [estados, setEstados] = useState([]);

    const [open, setOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1); // Estado para la página actual
    // sdasd

    const itemsPerPage = 54; // Máximo de artículos por página

    const [fechaInicio, setFechaInicio] = useState("2024-01-01");
    const [fechaFin, setFechaFin] = useState("2100-01-01");
    const [filtrar, setFiltrar] = useState(false)


    const filtrarFecha = (event) => {
        //console.log(event.target);
        let value = event.target.value;
        let name = event.target.name;

        switch (name) {
            case "fechaInicio":
                setFechaInicio(value);
                setFiltrar(true)
                break;

            case "fechaFin":
                setFechaFin(value);
                setFiltrar(true)
                break;

            default:

                break;
        }
    }


    const getTrabajo = async (idProyecto, fechaInicio, fechaFin) => {
        try {
            const response = await getTrabajosFecha(idProyecto, fechaInicio, fechaFin);
            //console.log("aa", response.infoPy)
            setTrabajos(response.data);
            setFiltrar(false)
            // setTrabajosFiltrados(response.data);
            // setTrabajos(filtrarTrabajos(response.data))
            setInfoPy(response.infoPy)
        } catch (error) {
            console.error("Error al añadir el cliente:", error);
        }
    }

    const handledOnChange = (event) => {
        setMostrarFinal(event.target.checked)
        // //console.log(event.target.checked)
    }

    const cargarEstados = async () => {
        try {
            const response = await getEstados();
            setEstados(response.data)

        } catch (error) {
            console.error("Error al añadir el cliente:", error);
        }

    }

    // Cálculo para dividir el array en páginas
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentTrabajos = trabajos.slice(indexOfFirstItem, indexOfLastItem);


    useEffect(() => {
        getTrabajo(param.idProyecto, fechaInicio, fechaFin);
        cargarEstados();
    }, [])


    useEffect(() => {
        //console.log(trabajosFiltrados);

    }, [trabajosFiltrados])



    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            xs={12}
            className='load'
            sx={{ pb: 10 }}
        >

            <Header></Header>

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                xs={11} md={10}
            >
                <SubHeader></SubHeader>
            </Grid>

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                xs={11} md={10}
                // className='borde-redondo sombra-corta'
                padding={2}
                gap={3}
            >
                <Grid
                    container
                    xs={12}
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Grid
                        container
                        direction="row"
                        xs={12} md={5}
                        justifyContent="flex-start"
                        alignItems="center"
                    >
                        <Grid item xs={2} md={2}>
                            <GroupIcon sx={{ fontSize: 50 }} color='negro' />
                        </Grid>

                        <Grid item xs={9} md={8} alignItems="flex-start">
                            <Typography variant="h4" style={{ textAlign: 'left' }}>
                                {infoPy.nombre}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        direction="row"
                        xs={12} md={7}
                        justifyContent="flex-end"
                        alignItems="center"
                        gap={1}
                    >
                        <Grid item xs={12} md={2.2} >
                            <TextField
                                fullWidth
                                id="fecha-inicio"
                                label="Fecha de inicio"
                                variant="outlined"
                                type="date"
                                value={fechaInicio}
                                color="azul"
                                name="fechaInicio"
                                InputLabelProps={{
                                    shrink: true, // Asegura que el label no se superponga
                                }}
                                onChange={filtrarFecha} // Maneja los cambios
                            />
                        </Grid>
                        <Grid item xs={12} md={2.2} >
                            <TextField
                                fullWidth
                                id="outlined-basic"
                                label="Fecha hasta..."
                                variant="outlined"
                                type='date'
                                value={fechaFin}
                                color='azul'
                                name='fechaFin'
                                InputLabelProps={{
                                    shrink: true, // Asegura que el label no se superponga
                                }}
                                onChange={filtrarFecha} // Maneja los cambios
                            />
                        </Grid>
                        {
                            filtrar ?
                                <Grid item xs={12} md={2}>
                                    <Button color='azul' fullWidth variant='outlined' sx={{ pt: 1.8, pb: 1.8 }} onClick={() => getTrabajo(param.idProyecto, fechaInicio, fechaFin)}>Filtrar</Button>
                                </Grid>
                                : ''
                        }
                        {
                            infoPy.id_estado == "2" || infoPy.id_estado == "3"
                                ? ''
                                :
                                <Grid item xs={12} md={3}>
                                    <Button fullWidth color='azul' variant="contained" onClick={() => Navigate("/crear-trabajo/" + param.idProyecto)}><LoupeIcon fontSize='small' sx={{ mr: 1, mb: 0.3, pt: 1.2, pb: 1.2 }} /> Crear trabajo</Button>
                                </Grid>
                        }

                    </Grid>
                    <div className="separador"></div>
                </Grid>
                {trabajos.length >= 1 && (
                    <FiltrosTrabajos trabajos={trabajos} setTrabajosFiltrados={setTrabajosFiltrados} estados={estados} setEstados={setEstados} ></FiltrosTrabajos>
                )}

                {trabajosFiltrados.length <= 0
                    ? <div>
                        <Typography variant="h4">
                            No hay trabajos
                        </Typography>
                    </div>
                    : <ListaTrabajos trabajos={trabajosFiltrados} setTrabajos={setTrabajos} setTrabajosFinal={setTrabajosFinal} idProyecto={param.idProyecto}></ListaTrabajos>
                }
            </Grid>


            <CerrarSesion></CerrarSesion>
        </Grid>
    )
}
